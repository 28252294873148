import firebase from 'firebase/app';
import { Card } from 'antd';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Form, Input, Select, Button, Radio, List } from 'antd';
import { PlusOutlined, CloseOutlined, CopyOutlined, CodeOutlined } from '@ant-design/icons';
import ShippingFee from './fees';
import { getMerchant } from "../api";
import LogoUploader from './uploadfile'
import { Store, SocialAcc, MenuOption } from "../store";
import { uuid } from "./utils";
import { CopyToClipboard } from 'react-copy-to-clipboard';
const { Option } = Select;
const { Search, TextArea } = Input
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
interface UserPlan {
    plan: 'Business' | 'Starter',
    userCount: number,
}
const defaultTheme = {
    primary: {
        main: "#303030",
        dark: "#212121",
        light: "#595959",
    },
    secondary: {
        main: "#656565",
        dark: "#464646",
        light: "#838383",
    },
}
const defaultCurrency = {
    exchangeRate: 1,
    template: "<%=value?value.toLocaleString():0%><%=currency && currency%>",
    currencySymbol: "K",
    currencyCode: "MMK",
}
export const SocialTypes = {
    TWITTER: 'twitter',
    FACEBOOK: 'facebook',
    LINKEDIN: 'linkedin',
    YOUTUBE: 'youtube',
    INSTAGRAM: 'instagram'
}
export const MenuTypes = {
    DELIVERY: 'delivery',
    PICKUP: 'pick-up',
    DINEIN: 'dine-in'
}
export const PaymentTypes = {
    CASH: 'cash',
    BANK: 'bank',
}
const initAccs: Array<{ name: string, number: any }> = [];
const initSocialAccs: Array<{ name: string, id: string }> = [];
const initShops: Array<Store> = [];
const initMenus: Array<MenuOption> = [];
const initPayments: Array<MenuOption> = [];
const getMerchantTypeById = (id: number) => {
    if (id === 1) return 'hotel'
    if (id === 2) return 'restaurant'
    return 'default'
}
const orderTextCodes = [{ code: '{shop_name}', description: 'shop name', name: 'businessName' },
{ code: '{customer_phone}', description: 'customer phone number', name: 'customerName' },
{ code: '{customer_name}', description: 'customer name', name: 'customerName' },
{ code: '{order_no}', description: 'web order number', name: 'orderNumber' },
]
const WebPortal = () => {
    const alert = useAlert()

    const [loading, setLoading] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false)
    const [enable, setEnable] = useState('disable');
    const [posShippingSystemEnabled, setPosShippingSystemEnabled] = useState(false)
    const [posPointSystemEnabled, setPosPointSystemEnabled] = useState(false)
    const [rewardIntegration, setRewardIntegration] = useState(false)
    const [posBusinessIntelligentEnable, setPosBusinessIntelligentEnable] = useState(false)
    const [posBusinessIntelligentUrl, setposBusinessIntelligentUrl] = useState("");
    const [productPlan, setProductPlan] = useState(500);
    const [userPlan, setUserPlan] = useState(1)
    const [merchantId, setMerchantId] = useState('')
    const [merchantType, setMerchantType] = useState(0)
    const [onlineStore, setOnlineStore] = useState("")
    const [showOrderCompleteCode, setOrderCompleteCode] = useState(false);
    const [termAndConditions, setTermAndCondition] = useState("")
    const [orderCompleteThankyouText, setorderCompleteThankyouText] = useState("Your order number is {order_no}. We have sent sms your order confirmation and will send you and update when your order has shipped")
    const [paymentShippingInfo, setPaymentShippingInfo] = useState("")
    const [bankAccounts, setBankAccounts] = useState(initAccs)
    const [menuOptions, setMenuOptions] = useState(initMenus);
    const [paymentOptions, setPaymentOptions] = useState(initPayments);
    const [socialAccounts, setSocialAccounts] = useState(initSocialAccs)
    const [theme, setTheme] = useState({ ...defaultTheme })
    const [currency, setCurrency] = useState({ ...defaultCurrency })
    const [photos, setPhotos] = useState({ favicon: '', logo: '' })
    const [shops, setShops] = useState(initShops);
    const [copyIndex, setCopyIndex] = useState(-1)
    const copied = (i: number) => i === copyIndex;

    //const [fees, setFees] = useState([])
    const merchantExist = !(!(typeof merchantId === 'string') || merchantId.length === 0)
    const addBankAcc = () => {
        let _news = [...bankAccounts]
        _news.push({ name: '', number: 0 })
        setBankAccounts(_news)
    }
    const rmBankAcc = (index: number) => {
        setBankAccounts(bankAccounts.filter((item: any, i: number) => i !== index))
    }
    const onBankChange = (index: number, key: string, event: any) => {
        let _news = [...bankAccounts]
        _news[index] = { ..._news[index], [key]: event.target.value }
        setBankAccounts(_news)
    }
    const addShop = () => {
        let _news = [...shops]
        _news.push({ id: uuid(), address: "", status: 'inactive' })
        setShops(_news)
    }
    const rmShop = (index: number) => {
        setShops(shops.filter((item: Store, i: number) => i !== index))
    }
    const onShopChange = (index: number, key: string, value: any) => {
        let _news = [...shops]
        _news[index] = { ..._news[index], [key]: value }
        setShops(_news)
    }
    const addPayment = () => {
        let _news = [...paymentOptions]
        _news.push({ type: '', status: 'inactive' })
        setPaymentOptions(_news)
    }
    const rmPayment = (index: number) => {
        setPaymentOptions(paymentOptions.filter((item: MenuOption, i: number) => i !== index))
    }
    const onPaymentChange = (index: number, key: string, value: any) => {
        let _news = [...paymentOptions]
        _news[index] = { ..._news[index], [key]: value }
        setPaymentOptions(_news)
    }
    const addMenu = () => {
        let _news = [...menuOptions]
        _news.push({ type: '', status: 'inactive' })
        setMenuOptions(_news)
    }
    const rmMenu = (index: number) => {
        //setShops(shops.filter((item: Store, i: number) => i !== index))
        setMenuOptions(menuOptions.filter((item: MenuOption, i: number) => i !== index))
    }
    const onMenuChange = (index: number, key: string, value: any) => {
        let _news = [...menuOptions]
        _news[index] = { ..._news[index], [key]: value }
        setMenuOptions(_news)
    }
    const addSocial = () => {
        let _news = [...socialAccounts]
        _news.push({ name: '', id: '' })
        setSocialAccounts(_news)
    }
    const rmSocial = (index: number) => {
        setSocialAccounts(socialAccounts.filter((item: SocialAcc, i: number) => i !== index))
    }
    const onSocialChange = (index: number, key: string, value: any) => {
        let _news = [...socialAccounts]
        _news[index] = { ..._news[index], [key]: value }
        setSocialAccounts(_news)
    }
    const disableController = (newValue: any) => {
        setEnable(newValue)
    }
    const onPlanChange = (e: any) => {
        setUserPlan(e.target.value)
    }
    const onMerchantTypeChange = (e: any) => {
        setMerchantType(e.target.value)
    }
    const onLogoUpload = (path: string) => {
        setPhotos({ ...photos, logo: path })
    }
    const onFaviconUpload = (path: string) => {
        setPhotos({ ...photos, favicon: path })
    }
    const onThemeChange = (type: string, code: string, value: string) => {
        setTheme({
            ...theme,
            [type]: {
                ...theme[type],
                [code]: value
            }
        })
    }
    const onCurrencyChange = (field: string, value: any) => {
        setCurrency({
            ...currency,
            [field]: value
        })
    }
    const onSearch = async (merchantId: string) => {
        if (!(typeof merchantId === 'string') || merchantId.length === 0) {
            // alert.info('Invalid Merchant ID.')
            return
        }
        setSearchLoading(true)
        const r = await getMerchant(merchantId)
        if (r.success && r.merchant) {
            setMerchantId(merchantId)
            if (r.merchant.posEnabled) {
                setEnable(r.merchant.posEnabled ? 'enable' : 'disable')
            }
            if (r.merchant.theme) {
                let t = { ...defaultTheme, ...r.merchant.theme.palette }
                if (r.merchant.theme.palette.secondary) {
                    t = { ...t, secondary: r.merchant.theme.palette.secondary }
                }
                setTheme({ ...t })
            }
            if (r.merchant.bankAccounts && (Array.isArray(r.merchant.bankAccounts))) {
                setBankAccounts(r.merchant.bankAccounts)
            } else {
                if (r.merchant.bankAccount && (typeof r.merchant.bankAccount === 'object')) {
                    let arr: Array<{ name: string, number: any }> = []
                    let values = Object.values(r.merchant.bankAccount)
                    Object.keys(r.merchant.bankAccount).map((k, i) => arr.push({ name: k, number: values[i] }))
                    setBankAccounts(arr)
                }
            }
            if (r.merchant.socailAccounts) {
                setSocialAccounts(r.merchant.socailAccounts)
            }
            if (r.merchant.menuOptions) {
                setMenuOptions(r.merchant.menuOptions);
            }
            if (r.merchant.paymentOptions) {
                setPaymentOptions(r.merchant.paymentOptions);
            }
            if (r.merchant.thankyouText) {
                setorderCompleteThankyouText(r.merchant.thankyouText)
            }
            if (r.merchant.currency) {
                setCurrency(r.merchant.currency)
            }
            if (r.merchant.posSystem) {
                if (r.merchant.posSystem.plan === 'Business') {
                    setUserPlan(2)
                }
                if (r.merchant.posSystem.itemLimitationCount) {
                    const limit = r.merchant.posSystem.itemLimitationCount;
                    if (limit === -1) {
                        setProductPlan(-1)
                    } else if (limit > 500 && limit <= 2000) {
                        setProductPlan(2000)
                    } else {
                        setProductPlan(500);
                    }
                }
            }
            if (r.merchant.merchantType) {
                if (r.merchant.merchantType === 'hotel') {
                    setMerchantType(1)
                }
                if (r.merchant.merchantType === 'restaurant') {
                    setMerchantType(2)
                }
            }
            if (r.merchant.paymentAndShippingInfo) {
                setPaymentShippingInfo(r.merchant.paymentAndShippingInfo);
            }
            if (r.merchant.posShippingSystemEnabled) {
                setPosShippingSystemEnabled(r.merchant.posShippingSystemEnabled)
            }
            if (r.merchant.rewardIntegrationEnabled) {
                setRewardIntegration(r.merchant.rewardIntegrationEnabled);
            }
            if (r.merchant.posBusinessIntelligentEnable) {
                setPosBusinessIntelligentEnable(r.merchant.posBusinessIntelligentEnable);
            }
            if (r.merchant.posBusinessIntelligentUrl) {
                setposBusinessIntelligentUrl(r.merchant.posBusinessIntelligentUrl);
            }
            if (r.merchant.posPointSystemEnabled) {
                setPosPointSystemEnabled(r.merchant.posPointSystemEnabled);
            }
            if (r.merchant.enterpriseSetting) {
                setOnlineStore(r.merchant.enterpriseSetting.onlineStore)
            }
            if (r.merchant.termAndConditions) {
                setTermAndCondition(r.merchant.termAndConditions);
            }
            if (r.merchant.stores) {
                setShops(r.merchant.stores)
            }
            setPhotos({
                ...photos,
                favicon: r.merchant.favicon ? r.merchant.favicon : '',
                logo: r.merchant.logo ? r.merchant.logo : ''
            })
        }
        setSearchLoading(false)
        return
    }
    const submitHandler = async () => {
        if (!(typeof merchantId === 'string') || merchantId.length === 0) {
            alert.info('Invalid Merchant ID.')
            return
        }
        setLoading(true)
        let _plan: UserPlan
        if (userPlan === 2) {
            _plan = { userCount: 5, plan: 'Business' }
        } else {
            _plan = { userCount: 2, plan: 'Starter' }
        }

        let other: any = {}
        // set payment and shipping notice info
        other.paymentAndShippingInfo = paymentShippingInfo;
        //set terms and conditions
        other.termAndConditions = termAndConditions;
        // set web order completed thank you text
        other.thankyouText = orderCompleteThankyouText
        other.posSystem = { ..._plan, itemLimitationCount: productPlan }
        // set merchant type
        other.merchantType = getMerchantTypeById(merchantType)
        //set theme 
        other.theme = { palette: {} }
        other.theme.palette.primary = theme.primary
        other.theme.palette.secondary = theme.secondary
        //set bank accounts

        // let obj: any = {}
        // bankAccounts.filter(v => v.number !== 0).forEach(v => {
        //     obj = { ...obj, [v.name]: v.number }
        // })
        // other.bankAccount = { ...obj }
        other.bankAccounts = bankAccounts.filter(b => b.number !== 0)
        // set menu options
        other.menuOptions = menuOptions;
        //set payment options
        other.paymentOptions = paymentOptions;
        // set social accounts
        other.socailAccounts = socialAccounts
        //set currency
        other.currency = { ...currency }

        //set shops
        other.stores = shops
        // set posShippingSystemEnabled
        other.posShippingSystemEnabled = posShippingSystemEnabled
        other.posPointSystemEnabled = posPointSystemEnabled
        other.posBusinessIntelligentEnable = posBusinessIntelligentEnable
        other.posBusinessIntelligentUrl = posBusinessIntelligentUrl
        other.rewardIntegrationEnabled = rewardIntegration

        // set online store link
        other.enterpriseSetting = { onlineStore }
        if (photos.favicon.length !== 0) {
            other.favicon = photos.favicon
        }
        if (photos.logo.length !== 0) {
            other.logo = photos.logo
        }

        const updateMerchant = firebase.functions().httpsCallable('updateMechantOptions');
        const result = await updateMerchant({
            merchantId: merchantId,
            field: 'posEnabled', value: enable === 'enable' ? true : false,
            other
        });
        const authResult = result.data;
        if (authResult.success) {
            alert.success(`Update  MCommerce ${merchantId} success.`)
        } else {
            alert.error(`Update  MCommerce ${merchantId} failed. [${authResult.message}]`)
        }
        setLoading(false)
    }
    return (
        <Form
            {...layout}
        >
            <Form.Item
                {...tailLayout}
            >
                <Search
                    placeholder="enter search merchantId "
                    allowClear
                    //enterButton="Search"
                    size="large"
                    style={{ width: '50%' }}
                    onSearch={onSearch}
                    loading={searchLoading}
                />
            </Form.Item>
            <Form.Item
                label='MercantId'
                rules={[{ required: true }]}>
                <Input
                    placeholder={merchantId}
                    // onChange={onMerchantChange}
                    style={{ width: '30%' }}
                    disabled
                />
            </Form.Item>
            <Form.Item
                label="POSEnable"
            >
                <Select
                    value={enable}
                    style={{ width: 100, margin: '0 8px' }}
                    onChange={disableController}
                >
                    <Option value='enable'>Enable</Option>
                    <Option value='disable'>Disable</Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="User Plan"
            >
                <Radio.Group name="radiogroup"
                    value={userPlan}
                    onChange={(e: any) => onPlanChange(e)}
                >
                    <Radio value={1} >Starter</Radio>
                    <Radio value={2} >Business</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="Product Plan"
            >
                <Radio.Group name="radiogroup"
                    value={productPlan}
                    onChange={(e: any) => setProductPlan(e.target.value)}
                >
                    <Radio value={500} >Starter</Radio>
                    <Radio value={2000} >Business</Radio>
                    <Radio value={-1} >Growth</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="POS Shipping System Enabled"
            >
                <Radio.Group name="radiogroup"
                    value={posShippingSystemEnabled}
                    onChange={(e: any) => setPosShippingSystemEnabled(e.target.value)}
                >
                    <Radio value={false} >Disable</Radio>
                    <Radio value={true} >Enable</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="Reward Integration Enabled"
            >
                <Radio.Group name="radiogroup"
                    value={rewardIntegration}
                    onChange={(e: any) => setRewardIntegration(e.target.value)}
                >
                    <Radio value={false} >Disable</Radio>
                    <Radio value={true} >Enable</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="POS Point System Enabled"
            >
                <Radio.Group name="radiogroup"
                    value={posPointSystemEnabled}
                    onChange={(e: any) => setPosPointSystemEnabled(e.target.value)}
                >
                    <Radio value={false} >Disable</Radio>
                    <Radio value={true} >Enable</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="BI Report Enabled"
            >
                <Radio.Group name="radiogroup"
                    value={posBusinessIntelligentEnable}
                    onChange={(e: any) => setPosBusinessIntelligentEnable(e.target.value)}
                >
                    <Radio value={false} >Disable</Radio>
                    <Radio value={true} >Enable</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="BI report url"
            >
                <Input
                    placeholder='https://datastudio.google.com/embed/reporting'
                    addonBefore='BI'
                    value={posBusinessIntelligentUrl}
                    onChange={(v: any) => setposBusinessIntelligentUrl(v.target.value)}
                    style={{ width: '40%' }}
                />
            </Form.Item>
            <Form.Item
                label="Online Store"
            >
                <Input
                    placeholder='https://xxxxx.piticommerce.com'
                    addonBefore='Website'
                    value={onlineStore}
                    onChange={(v: any) => setOnlineStore(v.target.value)}
                    style={{ width: '40%' }}
                />
            </Form.Item>
            <Form.Item
                label="Web Order Complete Text"
            >
                <TextArea
                    placeholder='Thank you for your order{order_no}.'
                    addonBefore='thankyou'
                    value={orderCompleteThankyouText}
                    onChange={(v: any) => setorderCompleteThankyouText(v.target.value)}
                    style={{ width: '50%' }}
                />
                <br />
                {
                    !showOrderCompleteCode ? <Button type='primary' icon={<CodeOutlined />} onClick={() => setOrderCompleteCode(true)}>
                        show message codes
                    </Button> :
                        <Button type='primary' icon={<CodeOutlined />} onClick={() => setOrderCompleteCode(false)}>
                            hide message codes
                        </Button>
                }

                {showOrderCompleteCode && <List
                    itemLayout="horizontal"
                    dataSource={orderTextCodes}
                    bordered={false}
                    split={false}
                    renderItem={(item: { name: string, description: string, code: string }, index: number) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Input value={item.name} disabled={true} />}
                                title={<Input value={item.code} disabled={true}
                                    addonAfter={
                                        <CopyToClipboard
                                            text={item.code}
                                            onCopy={() => setCopyIndex(index)}
                                        >
                                            <span>
                                                {
                                                    copied(index) ? <a style={{ color: 'green' }}>Copied!</a> : <CopyOutlined />
                                                }
                                            </span>
                                        </CopyToClipboard>
                                    } />}

                                style={{ width: '50%' }}
                            />
                            {item.description}
                        </List.Item>
                    )} />
                }
            </Form.Item>
            <Form.Item
                label="Payment and Shipping notice information"
            >
                <TextArea
                    placeholder='Payment and shipping customer notice information.'
                    addonBefore='info'
                    value={paymentShippingInfo}
                    onChange={(v: any) => setPaymentShippingInfo(v.target.value)}
                    style={{ width: '50%' }}
                />
            </Form.Item>
            {
                merchantExist && (

                    <Form.Item
                        label="Shipping Fees"
                    >
                        <ShippingFee merchantId={merchantId} />
                    </Form.Item>
                )
            }

            <Form.Item
                label="Merchant Type"
            >
                <Radio.Group name="radiogroup"
                    value={merchantType}
                    onChange={(e: any) => onMerchantTypeChange(e)}
                >
                    <Radio value={0} >Default</Radio>
                    <Radio value={1} >Hotel</Radio>
                    <Radio value={2} >Restaurant</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="Banck Account"
            >
                {bankAccounts.length > 0 && <List
                    itemLayout="horizontal"
                    dataSource={bankAccounts}
                    bordered={false}
                    split={false}
                    renderItem={(item: any, index: number) => (
                        <List.Item
                            style={{ width: '70%' }}
                            extra={
                                <Button shape="circle" icon={<CloseOutlined />} onClick={() => rmBankAcc(index)} />
                            }
                        >
                            <List.Item.Meta
                                avatar={<Input placeholder="bank account name" value={item.name} onChange={(e: any) => onBankChange(index, 'name', e)} />}
                                title={<Input placeholder='bank account number' value={item.number} onChange={(e: any) => onBankChange(index, 'number', e)} />}
                            />
                        </List.Item>
                    )} />
                }
                <Button icon={<PlusOutlined />} onClick={() => addBankAcc()}>Add Account</Button>
            </Form.Item>
            <Form.Item
                label='Payment Methods'
            >
                {
                    paymentOptions.length > 0 && <List
                        itemLayout="horizontal"
                        dataSource={paymentOptions}
                        bordered={false}
                        split={false}
                        renderItem={(item: MenuOption, index: number) => (
                            <List.Item
                                style={{ width: '60%' }}
                                extra={
                                    <Button shape="circle" icon={<CloseOutlined />} onClick={() => rmPayment(index)} />
                                }
                            >
                                <List.Item.Meta
                                    avatar={<Select
                                        value={item.type}
                                        style={{ width: 200, margin: '0 8px' }}
                                        onChange={(v: any) => onPaymentChange(index, 'type', v)}
                                    >
                                        <Option value={PaymentTypes.CASH}>Cash On Delivery</Option>
                                        <Option value={PaymentTypes.BANK}>Bank Transfer</Option>
                                    </Select>}
                                    title={
                                        <Select
                                            value={item.status}
                                            style={{ width: 100, margin: '0 8px' }}
                                            onChange={(v: any) => onPaymentChange(index, 'status', v)}
                                        >
                                            <Option value='active'>Active</Option>
                                            <Option value='inactive'>Inactive</Option>
                                        </Select>}
                                ></List.Item.Meta>
                            </List.Item>
                        )}
                    />

                }
                <Button icon={<PlusOutlined />} onClick={() => addPayment()}>Add Payment</Button>
            </Form.Item>
            <Form.Item
                label='Socail Links'
            >
                {
                    socialAccounts.length > 0 && <List
                        itemLayout="horizontal"
                        dataSource={socialAccounts}
                        bordered={false}
                        split={false}
                        renderItem={(item: SocialAcc, index: number) => (
                            <List.Item
                                style={{ width: '60%' }}
                                extra={
                                    <Button shape="circle" icon={<CloseOutlined />} onClick={() => rmSocial(index)} />
                                }
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Select
                                            value={item.name}
                                            style={{ width: 150, margin: '0 8px' }}
                                            onChange={(v: any) => onSocialChange(index, 'name', v)}
                                        >
                                            <Option value={SocialTypes.FACEBOOK}>Facebook</Option>
                                            <Option value={SocialTypes.YOUTUBE}>Youtube</Option>
                                            <Option value={SocialTypes.LINKEDIN}>LinkedIn</Option>
                                            <Option value={SocialTypes.INSTAGRAM}>Instagram</Option>
                                            <Option value={SocialTypes.TWITTER}>Twitter</Option>
                                        </Select>
                                    }
                                    title={
                                        <Input
                                            placeholder="social link id"
                                            addonBefore='socialId'
                                            value={item.id}
                                            onChange={(e: any) => onSocialChange(index, 'id', e.target.value)}
                                        />
                                    }
                                ></List.Item.Meta>
                            </List.Item>
                        )}
                    />

                }
                <Button icon={<PlusOutlined />} onClick={() => addSocial()}>Add SocialLink</Button>
            </Form.Item>

            {
                getMerchantTypeById(merchantType) === 'restaurant' &&
                <Form.Item
                    label='Shops'
                >
                    {
                        shops.length > 0 && <List
                            itemLayout="horizontal"
                            dataSource={shops}
                            bordered={false}
                            split={false}
                            renderItem={(item: Store, index: number) => (
                                <List.Item
                                    style={{ width: '60%' }}
                                    extra={
                                        <Button shape="circle" icon={<CloseOutlined />} onClick={() => rmShop(index)} />
                                    }
                                >
                                    <List.Item.Meta
                                        avatar={<Input
                                            placeholder="shop address"
                                            addonBefore='address'
                                            value={item.address}
                                            onChange={(e: any) => onShopChange(index, 'address', e.target.value)}
                                        />}
                                        title={
                                            <Select
                                                value={item.status}
                                                style={{ width: 100, margin: '0 8px' }}
                                                onChange={(v: any) => onShopChange(index, 'status', v)}
                                            >
                                                <Option value='active'>Public</Option>
                                                <Option value='inactive'>Unpublic</Option>
                                            </Select>}
                                    ></List.Item.Meta>
                                </List.Item>
                            )}
                        />

                    }
                    <Button icon={<PlusOutlined />} onClick={() => addShop()}>Add Shop</Button>
                </Form.Item>
            }
            {
                getMerchantTypeById(merchantType) === 'restaurant' &&
                <Form.Item
                    label='Menus'
                >
                    {
                        menuOptions.length > 0 && <List
                            itemLayout="horizontal"
                            dataSource={menuOptions}
                            bordered={false}
                            split={false}
                            renderItem={(item: MenuOption, index: number) => (
                                <List.Item
                                    style={{ width: '60%' }}
                                    extra={
                                        <Button shape="circle" icon={<CloseOutlined />} onClick={() => rmMenu(index)} />
                                    }
                                >
                                    <List.Item.Meta
                                        avatar={<Select
                                            value={item.type}
                                            style={{ width: 100, margin: '0 8px' }}
                                            onChange={(v: any) => onMenuChange(index, 'type', v)}
                                        >
                                            <Option value={MenuTypes.DELIVERY}>Delivery</Option>
                                            <Option value={MenuTypes.PICKUP}>PickUp</Option>
                                            <Option value={MenuTypes.DINEIN}>DineIn</Option>
                                        </Select>}
                                        title={
                                            <Select
                                                value={item.status}
                                                style={{ width: 100, margin: '0 8px' }}
                                                onChange={(v: any) => onMenuChange(index, 'status', v)}
                                            >
                                                <Option value='active'>Active</Option>
                                                <Option value='inactive'>InActive</Option>
                                            </Select>}
                                    ></List.Item.Meta>
                                </List.Item>
                            )}
                        />

                    }
                    <Button icon={<PlusOutlined />} onClick={() => addMenu()}>Add Menu</Button>
                </Form.Item>
            }
            <Form.Item
                label="Primary Theme"
            >
                <Input
                    addonBefore='main'
                    placeholder='main color code'
                    value={theme.primary.main}
                    onChange={(e: any) => onThemeChange('primary', 'main', e.target.value)}
                    style={{ width: '20%' }}
                />
                <Input
                    placeholder='dark color code'
                    addonBefore='dark'
                    value={theme.primary.dark}
                    onChange={(v: any) => onThemeChange('primary', 'dark', v.target.value)}
                    style={{ width: '20%' }}
                />
                <Input
                    placeholder='light color code'
                    addonBefore='light'
                    value={theme.primary.light}
                    onChange={(v: any) => onThemeChange('primary', 'light', v.target.value)}
                    style={{ width: '20%' }}
                />
            </Form.Item>
            <Form.Item
                label="Secondary Theme"
            >
                <Input
                    addonBefore='main'
                    placeholder='main color code'
                    value={theme.secondary.main}
                    onChange={(v: any) => onThemeChange('secondary', 'main', v.target.value)}
                    style={{ width: '20%' }}
                />
                <Input
                    placeholder='dark color code'
                    addonBefore='dark'
                    value={theme.secondary.dark}
                    onChange={(v: any) => onThemeChange('secondary', 'dark', v.target.value)}
                    style={{ width: '20%' }}
                />
                <Input
                    placeholder='light color code'
                    addonBefore='light'
                    value={theme.secondary.light}
                    onChange={(v: any) => onThemeChange('secondary', 'light', v.target.value)}
                    style={{ width: '20%' }}
                />
            </Form.Item>
            <Form.Item
                label="Currency"
            >
                <Input
                    addonBefore='code'
                    placeholder='currency code '
                    value={currency.currencyCode}
                    onChange={(e: any) => onCurrencyChange('currencyCode', e.target.value)}
                    style={{ width: '20%' }}
                />
                <Input
                    placeholder='currency symbol'
                    addonBefore='symbol'
                    value={currency.currencySymbol}
                    onChange={(v: any) => onCurrencyChange('currencySymbol', v.target.value)}
                    style={{ width: '20%' }}
                />
                <Input
                    placeholder='exchangeRate'
                    addonBefore='exchange rate'
                    value={currency.exchangeRate}
                    onChange={(v: any) => onCurrencyChange('exchangeRate', v.target.value)}
                    style={{ width: '25%' }}
                />
            </Form.Item>
            <Form.Item
                label="Terms & Conditions"
            >
                <TextArea
                    placeholder='terms and conditions'
                    addonBefore='Terms&Conditions'
                    value={termAndConditions}
                    onChange={(v: any) => setTermAndCondition(v.target.value)}
                    style={{ width: '50%' }}
                />

            </Form.Item>
            <Form.Item
                label="Web Logo"
            >
                <LogoUploader imgUrl={onLogoUpload} defaultUrl={photos.logo} />
            </Form.Item>
            <Form.Item
                label="Web Favicon Icon"
            >
                <LogoUploader imgUrl={onFaviconUpload} defaultUrl={photos.favicon} />
            </Form.Item>
            <Form.Item
                {...tailLayout}
            >
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={submitHandler}
                    loading={loading}
                >
                    Save
                </Button>
            </Form.Item>
        </Form>
    );
};

class Toolkit extends React.Component<{}, {}> {
    constructor(props: {}) {
        super(props)
        this.state = {
            status: 'loading'
        }
    }
    render() {
        return (
            <Card title="M-Commerce"
            >
                <WebPortal />

            </Card>
        );
    }
}

export default Toolkit;
