import React, { useState, useEffect } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Card, } from 'antd';
import { Button } from 'antd';
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { useAlert } from 'react-alert';
import firebase from 'firebase/app';
import CreateSMS from './createSms'
import MessageCode from './smsMessageCode';
import { Return, SMS } from '../tools/state';
import Item from 'antd/lib/list/Item';
// import moment from 'moment';
const { TextArea, Search } = Input
interface Item {
    key: string;
    merchantId: string;
    cancel: string;
    confirm: string;
    pending: string;
}
const originData: Item[] = [];
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: Item;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const disable = dataIndex === 'merchantId'
    const inputNode = inputType === 'number' ? <InputNumber disabled={disable} /> : <TextArea disabled={disable} />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const EditableTable = () => {
    const alert = useAlert();
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [deleteKey, setDeleteKey] = useState('')
    const [openCreate, setOpenCreate] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [state, setState] = useState({ status: 'loading', data: originData, saveLoading: false })
    const isEditing = (record: Item) => record.key === editingKey;
    const isDeleting = (record: Item) => record.key === deleteKey;
    useEffect(() => {
        const getAllHosts = firebase.functions().httpsCallable('getAllSms');
        getAllHosts().then((result) => {
            const { status, data } = result.data as { status: 'success' | 'invalid', data?: any }
            if (status === 'success') {
                const hostList = data as Array<{
                    pending: string,
                    cancelled: string,
                    confirmed: string,
                    merchantId: string
                }>
                const formatData = hostList.map((h, index) => {
                    return {
                        key: index.toString(),
                        confirm: h.confirmed,
                        cancel: h.cancelled,
                        pending: h.pending,
                        merchantId: h.merchantId
                    } as Item
                })
                const _state = { ...state }
                setState({ ..._state, status: 'loaded', data: formatData })
            }
        }).catch(error => {
            console.log(error)
        });
        // const shopDasborad = firebase.functions().httpsCallable('shopDashboard2');
        // const from = moment(1641749400000).startOf('day').unix() * 1000;
        // const to = moment(1641835800000).endOf('day').unix() * 1000;
        // console.log('from date', from, '\n to date', to);
        // shopDasborad({ merchantId: 'piticafe', fromDate: from, toDate: to }).then(res => console.log('shop dash', res)).catch((err) => console.log('shop dah err', err))
        // const shopDasborad = firebase.functions().httpsCallable('getMerchantRewards');
        // shopDasborad({ merchantId: 'piticafe' }).then(res => console.log('merchant rewards', res)).catch((err) => console.log('shop dah err', err))
        // const shopDasborad2 = firebase.functions().httpsCallable('getEProducts');
        // shopDasborad2({ merchantId: 'piticafe', queryType: 'previous', customerIdOrPhone: "09111111" }).then(res => console.log('merchant redeem members', res)).catch((err) => console.log('shop dah err', err))
    }, [])
    const edit = (record: Partial<Item> & { key: React.Key }) => {
        form.setFieldsValue({ host: '', merchantId: '', ...record });
        setEditingKey(record.key);
    };
    const onCreate = (v: Return) => {
        if (v.success) {
            setOpenCreate(false)
            let _news = [...state.data]
            const key = (_news.length + 1).toString();
            const { cancel, confirm, pending, merchantId } = v.data
            _news.unshift({ key, merchantId, cancel, confirm, pending })
            setState({ ...state, data: _news });
            alert.success('success')
        } else {
            alert.error(`Failed: ${v.message}`)
        }
    }
    const cancel = () => {
        setEditingKey('');
    };
    const onDelete = async (v: Item) => {
        setDeleteKey(v.key)
        const hostfunc = firebase.functions().httpsCallable('smsByAction');
        const result = await hostfunc({
            actionType: 'delete',
            merchantId: v.merchantId,
        });
        const { success, code } = result.data as { success: boolean, data?: any, code?: string }
        if (success) {
            alert.success(`success: delete ${v.merchantId}`)
            setState({ ...state, data: state.data.filter(b => b.key !== v.key) });
        } else {
            alert.error(`failed : delete ${v.merchantId} ${code}`)
        }
        setDeleteKey('');
    }
    const save = async (key: React.Key) => {
        setState({ ...state, saveLoading: true })
        try {
            const row = (await form.validateFields()) as Item;
            const hostfunc = firebase.functions().httpsCallable('smsByAction');
            const result = await hostfunc({
                actionType: 'update',
                merchantId: row.merchantId,
                data: { Pending: row.pending, Completed: row.confirm, Cancelled: row.cancel } as SMS
            });
            const { success, code: Error } = result.data as { success: boolean, data: any, code?: string }
            if (success) {
                const newData = [...state.data];
                const index = newData.findIndex(item => key === item.key);
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, {
                        ...item,
                        ...row,
                    });
                } else {
                    newData.push(row);
                }
                setState({ ...state, data: newData, saveLoading: false });
                setEditingKey('');
                alert.success(`success: ${row.merchantId} `)
            } else {
                alert.error(`failed:${row.merchantId} => ${Error}`)
                setState({ ...state, saveLoading: false })
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
            setState({ ...state, saveLoading: false })
        }
    };
    const onSearch = (value: any) => setSearchText(value);
    const columns = [
        {
            title: 'MerchantId',
            dataIndex: 'merchantId',
            width: '10%',
            editable: true,
            filteredValue: [searchText],
            onFilter: (value: string, record: Item) => record.merchantId.includes(value),
            ellipsis: true,
        },
        {
            title: 'Pending',
            dataIndex: 'pending',
            editable: true,
        },
        {
            title: 'Confirm',
            dataIndex: 'confirm',
            editable: true,
        },
        {
            title: 'Cancel',
            dataIndex: 'cancel',
            //width: '15%',
            editable: true,
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            width: '12%',
            render: (_: any, record: Item) => {
                const editable = isEditing(record);
                return editable ? (
                    state.saveLoading ? <Button type='primary' loading >loading</Button> :
                        <span >
                            <Popconfirm title={`Sure to save ${record.merchantId}?`} onConfirm={() => save(record.key)}>
                                {/* <a href="javascript:;" onClick={() => save(record.key)} style={{ marginRight: 8 }}>
                                Save
                          </a> */}
                                <a style={{ marginRight: 8 }}>Save</a>
                            </Popconfirm>
                            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                                <a style={{ color: 'red' }}>Cancel</a>
                            </Popconfirm>
                        </span>
                )
                    : (
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            <EditOutlined />  Edit
                        </Typography.Link>
                    );
            },
        },
        {
            title: 'action',
            //width: '25%',
            dataIndex: 'action',
            render: (_: any, record: Item) => {
                const deleting = isDeleting(record)
                return (
                    deleting ? <Button type='primary' loading>deleting...</Button> :
                        <Popconfirm title={`Sure to delete ${record.merchantId}?`} onConfirm={() => onDelete(record)}>
                            <Button icon={<DeleteOutlined />} danger >delete</Button>
                        </Popconfirm>
                );
            }
        },
    ];

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Item) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    return (
        <Card
            title="SMS"
            loading={state.status === 'loading'}
            extra={
                <>
                    <MessageCode />
                    <Button
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={() => setOpenCreate(!openCreate)}
                        style={{ marginLeft: 12 }}
                    >
                        Add SMS
                    </Button>
                </>
            }
        >
            {
                openCreate && (
                    <Card type="inner" title="Create Order SMS">
                        <CreateSMS cb={onCreate} />
                    </Card>
                )
            }

            <Card type={openCreate ? "inner" : ""}
                title="SMS list"
                style={{ marginTop: openCreate ? 16 : 0 }}
                extra={
                    <>
                        <Search
                            placeholder="search merchantId..."
                            allowClear
                            enterButton="Search"
                            size="default"
                            onSearch={onSearch}
                        />
                    </>
                }
            >
                <Form form={form} component={false}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        bordered
                        dataSource={state.data}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: cancel,
                        }}
                    />
                </Form>
            </Card>

        </Card>
    );
};

export default EditableTable;