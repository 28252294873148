import firebase from 'firebase/app';
import * as React from 'react';
import { gauthAsync } from "../../api";
import { GoogleLogin, GoogleLoginResponse } from 'react-google-login';
import { Button } from 'antd';
const clientId = process.env.REACT_APP_GOOGLE_CREDENTIALS_CLIENT_ID || '';
class LoginForm2 extends React.Component<{}, { error: boolean, loading: boolean }> {
    constructor(props: {}) {
        super(props);
        this.state = {
            error: false,
            loading: false,
        }
        this.handleGLoginSuccess = this.handleGLoginSuccess.bind(this)
        this.handleGLoginError = this.handleGLoginError.bind(this)
    }
    handleGLoginSuccess = async (response: GoogleLoginResponse | any) => {
        this.setState({
            loading: true
        })
        const r = await gauthAsync(response.getAuthResponse().id_token);
        if (!r.success) {
            this.setState({
                error: true,
                loading: false,
            })
        } else if (r.token && r.userId) {
            try {
                await firebase.auth().signInWithCustomToken(r.token);
            } catch (error) {
                this.setState({
                    error: true,
                    loading: false,
                })
            }
        }
    }
    handleGLoginError = (err: any) => {
        this.setState({
            error: true,
            loading: false,
        })
    }
    renderButton = (props?: { onClick: () => void }) => {
        return (
            <Button
                loading={this.state.loading}
                type="primary"
                icon="google"
                style={{ backgroundColor: 'black' }}
                {...props}
            >
                Sign In
            </Button>);
    }
    render() {
        return (
            <div>
                <GoogleLogin
                    clientId={clientId}
                    hostedDomain="datafocus.cloud"
                    buttonText="Sign In"
                    onSuccess={this.handleGLoginSuccess}
                    onFailure={this.handleGLoginError}
                    render={this.renderButton}
                />
                <div className="login-error">
                    {this.state.error === true && <span>Invalid Datafocus Mail.</span>}
                </div>
            </div>
        );
    }
}

export default LoginForm2;
