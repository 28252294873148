
import { Card, Row, Col, Button, Popconfirm, Alert } from 'antd';
import React from 'react';
import firebase from 'firebase/app';
import { UserSwitchOutlined } from '@ant-design/icons';
import { Subscription } from 'rxjs';
import PasscodeConfirm from './passcode';
import PrimaryAccount from './primary_account';
import SecondaryAccount from './secondary_account';
import { Member } from './state';

type S =
    | { primary: Member | null, secondary: Member | null, authorized: boolean, confirmLoading: boolean, success: boolean | null }

class AccountClone extends React.Component<{}, S> {
    sub: Subscription;
    sub2: Subscription;
    constructor(props: {}) {
        super(props);
        this.sub = new Subscription();
        this.sub2 = new Subscription();
        this.state = { primary: null, secondary: null, authorized: false, confirmLoading: false, success: null };
        this.onAuthorize = this.onAuthorize.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }
    onAuthorize(authorize: boolean) {
        if (authorize) {
            this.setState({
                authorized: true
            })
        }
    }
    onSelect(type: string, member: Member) {
        if (type === 'primary') {
            this.setState({
                primary: member
            });
        }
        if (type === 'secondary') {
            this.setState({
                secondary: member
            });
        }
    }
    async onconfirm() {
        if (this.state.primary === null || this.state.secondary === null) return;
        if (!this.state.primary.phone || !this.state.secondary.id) return;
        const phone09 = "0" + this.state.primary.phone.substring(4);
        if (!phone09.startsWith('09')) return;
        // if (this.state.secondary.phone && this.state.secondary.phone.startsWith('+95')) return;
        //if (!this.state.secondary.email) return;
        this.setState({
            confirmLoading: true,
        });
        const func = firebase.functions().httpsCallable('mergeAccount');
        const result = await func({
            phone: phone09, //phone format 09
            memberId: this.state.secondary.id,
        });
        const { status } = result.data as { status: 'success' | 'invalid', data?: any };
        if (status === 'success') {
            this.setState({
                confirmLoading: false,
            });
            window.location.reload();
        } else {
            this.setState({
                confirmLoading: false,
                success: false,
            });
        }
    }
    render() {
        const authorized = this.state.authorized;
        const loading = this.state.confirmLoading;
        const visibleBtn = this.state.primary !== null && this.state.secondary !== null;
        const from = this.state.secondary !== null ? this.state.secondary : { id: '', phone: '', displayName: '' } as Member;
        const to = this.state.primary !== null ? this.state.primary : { id: '', phone: '', displayName: '' } as Member;
        return (
            authorized ?
                <Card
                    title='Royalty Account Clone'
                    loading={false}
                    extra={
                        visibleBtn && (
                            loading ? <Button type='primary' loading>loading</Button> :
                                < Popconfirm title={
                                    <span>
                                        Are you sure to merge? <br />
                                        From
                                        <br /> ---------<br />
                                        memberId:{from.id} <br />
                                        Name:{from.displayName}<br />
                                        phone:{from.phone}<br />
                                        <br />
                                        To:
                                        <br /> ---------<br />
                                        memberId:{to.id} <br />
                                        Name:{to.displayName}<br />
                                        phone:{to.phone}<br />
                                    </span>
                                }
                                 //   onConfirm={() => this.onconfirm()}
                                //   onConfirm={() => { }}
                                >
                                    <Button
                                        type='primary'
                                        icon={<UserSwitchOutlined />}
                                        onClick={() => { }}>Merge Account</Button>
                                </Popconfirm>
                        )
                    }
                >

                    <Row gutter={24}>
                        <Col span={24}>
                            {this.state.success && (
                                this.state.success ? <Alert message="Success Text" type="success" /> : <Alert message="Merge Failed" type="error" />
                            )}

                        </Col>
                    </Row>
                    <div style={{ height: 20 }} />
                    <Row gutter={24}>
                        <Col span={12}>
                            <PrimaryAccount onSelect={this.onSelect} />
                        </Col>
                        <Col span={12}>
                            <SecondaryAccount onSelect={this.onSelect} />
                        </Col>
                    </Row>
                </ Card >
                : <PasscodeConfirm cb={this.onAuthorize} />

        );
    }
}

export default AccountClone;
