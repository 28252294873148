import React from "react";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import { Progress } from 'antd';
const uploadPath = 'mcommerce'

interface P {
    imgUrl: (path: string) => void;
    defaultUrl?: string
}
export default class Uploader extends React.Component<P> {
    state = {
        filenames: [],
        downloadURLs: [],
        isUploading: false,
        uploadProgress: 0
    };
    componentDidMount() {
        const { defaultUrl } = this.props
        if (defaultUrl) {
            this.setState({ downloadURLs: [defaultUrl] })
        }
    }
    componentWillReceiveProps(props: P) {
        const { defaultUrl } = props
        if (defaultUrl) {
            this.setState({ downloadURLs: [defaultUrl] })
        }
    }
    handleUploadStart = () =>
        this.setState({
            isUploading: true,
            uploadProgress: 0
        });

    handleProgress = (progress: any) =>
        this.setState({
            uploadProgress: progress
        });

    handleUploadError = (error: any) => {
        this.setState({
            isUploading: false
            // Todo: handle error
        });
        console.error(error);
    };

    handleUploadSuccess = async (filename: any) => {
        const { imgUrl } = this.props
        const downloadURL = await firebase
            .storage()
            .ref(uploadPath)
            .child(filename)
            .getDownloadURL();
        imgUrl(downloadURL)
        this.setState(oldState => ({
            filenames: [filename],
            downloadURLs: [downloadURL],
            uploadProgress: 100,
            isUploading: false
        }));
    };

    render() {
        return (
            <div style={{ display: 'block' }}>
                <div>
                    <FileUploader
                        accept='image/*'
                        name="image-uploader-multiple"
                        randomizeFilename
                        storageRef={firebase.storage().ref(uploadPath)}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                        multiple
                    />
                </div>
                <div>
                    {this.state.isUploading ?
                        <Progress type="circle" percent={this.state.uploadProgress} /> : <div />
                    }
                </div>
                <div>
                    {this.state.downloadURLs.map((downloadURL, i) => {
                        // return <img key={i} src={downloadURL} />;
                        return <img
                            key={i}
                            width={120}
                            src={downloadURL}
                        />
                    })}
                </div>
            </div>
        );
    }
}
