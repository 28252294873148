import { Card, Empty, Input, Table, Button, Row, Col } from 'antd';
import firebase from 'firebase/app';
import React from 'react';
import { Subscription } from 'rxjs';
import { EyeOutlined } from '@ant-design/icons';
import { Member, MemberMerchant, MemberReward, PointSystem } from "./state";
import ButtonGroup from 'antd/lib/button/button-group';
const columns = (selectedMember: (memberId: string) => any) => [
    {
        title: 'Name',
        dataIndex: 'displayName',
        key: 'displayName',
        sorter: (a: Member, b: Member) =>
            a.displayName.localeCompare(b.displayName),
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Phone No',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: 'Action',
        key: 'action',
        align: 'center',
        // width: '12%',
        fixed: 'right',
        render: (v: number, record: Member) => {
            return (
                <ButtonGroup>
                    <Button shape="circle" icon={<EyeOutlined />}
                        onClick={() => selectedMember(record.id)}
                    />
                </ButtonGroup>
            );

        }
    }

];
const memberMerchantColumns = [
    {
        title: 'MerchantId',
        dataIndex: 'merchantId',
        key: 'merchantId',
        sorter: (a: MemberMerchant, b: MemberMerchant) =>
            a.businessName.localeCompare(b.businessName),
    },
    {
        title: 'BusinessName',
        dataIndex: 'businessName',
        key: 'businessName',
    },
    {
        title: 'Point',
        dataIndex: 'pointSystem',
        key: 'pointSystem',
        render: (v: PointSystem, record: MemberMerchant) => {
            return v.point || 0;
        },
    },

];

const memberRewardColumns = [
    {
        title: 'Reward Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a: MemberReward, b: MemberReward) =>
            a.name.localeCompare(b.name),
    },
    {
        title: 'MechantId',
        dataIndex: 'merchantId',
        key: 'merchantId',
    },
    {
        title: 'Mechant Name',
        dataIndex: 'merchantName',
        key: 'merchantName',

    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',

    },

];
type S =
    | { status: 'loading' | 'request', searchText: string }
    | {
        status: 'loaded';
        dataType: "list";
        data: Member[];
        searchText: string;
    } |
    {
        status: 'loaded';
        dataType: 'each';
        data: { member: Member, merchants: MemberMerchant[], rewards: MemberReward[] };
        searchText: string;
    };
interface P {
    onSelect: (type: string, member: Member) => any;
}
class PrimaryAccount extends React.Component<P, S> {
    sub: Subscription;
    constructor(props: P) {
        super(props);
        this.sub = new Subscription();
        this.state = { status: 'request', searchText: '' };
        this.onChange = this.onChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }
    onChange(search: any) {
        this.setState({ searchText: search })
    }
    async onSearch(e: Event) {
        e.preventDefault();
        if (this.state.searchText === '') return;
        this.setState({ status: 'loading' });
        const hostfunc = firebase.functions().httpsCallable('searchMemberByType');
        let _searchType = 'name';
        let _searchTerm = this.state.searchText;
        if (this.state.searchText.startsWith('09') ||
            this.state.searchText.startsWith('+95')
        ) {
            _searchType = 'phone'
            if (this.state.searchText.startsWith('09')) {
                _searchTerm = '+95 ' + this.state.searchText.substring(1);
            }
        }
        if (this.state.searchText.includes('@')) {
            _searchType = 'email'
        }
        const result = await hostfunc({
            searchType: _searchType,
            searchTerm: _searchTerm

        });
        const { success, data } = result.data as { success: boolean, data?: any }
        if (success) {
            this.setState({ status: 'loaded', data: data, searchText: '', dataType: 'list' });
        }
    }
    async onSelect(memberId: string) {
        if (this.state.status == 'loaded' && this.state.dataType == 'list') {
            const list = this.state.data;
            const index = list.findIndex((val: Member) => val.id === memberId)
            if (index > -1) {
                this.setState({ status: 'loading' });
                const m = list[index];
                const func = firebase.functions().httpsCallable('searchMemberByType');
                const result = await func({
                    searchType: 'detail',
                    searchTerm: m.id,
                });
                const { success, data } = result.data as { success: boolean, data?: any };
                if (success) {
                    this.props.onSelect('primary', m);
                    this.setState({
                        status: 'loaded',
                        dataType: 'each',
                        data: { member: m, ...data },
                        searchText: ''
                    })
                }
            }
        }
    }
    render() {
        const memberList = this.state.status === 'loaded' && this.state.dataType === 'list' ? this.state.data : [];
        const member = this.state.status === 'loaded' && this.state.dataType == 'each' ? this.state.data.member : { id: '', displayName: '' } as Member
        const merchants = this.state.status === 'loaded' && this.state.dataType == 'each' ? this.state.data.merchants : [];
        const rewards = this.state.status === 'loaded' && this.state.dataType == 'each' ? this.state.data.rewards : [];
        let widgets = <></>;
        if (this.state.status === 'loaded' && this.state.dataType === 'list') {
            widgets = <Table
                columns={columns(this.onSelect)}
                dataSource={memberList}
                pagination={{
                    pageSize: 25,
                }}
                size='small'
            />
        }
        if (this.state.status == 'request') {
            widgets = <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                    <span>
                        please search primary member
                    </span>
                }
            />
        }
        if (this.state.status == 'loaded' && this.state.dataType == 'each') {
            widgets = <>
                <Row gutter={24}>
                    <Col span={24}>
                        <Card
                            type="inner"
                            title={`${member.displayName}`}
                        >
                            <span>
                                {`MemberId: ${member.id || ''}`}<br />
                                {`Phone: ${member.phone || ''}`}<br />
                                {`Email: ${member.email || ''}`}<br />
                            </span>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table
                            columns={memberMerchantColumns}
                            dataSource={merchants}
                            pagination={{
                                pageSize: 10,
                            }}
                            size='small'
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table
                            columns={memberRewardColumns}
                            dataSource={rewards}
                            pagination={{
                                pageSize: 10,
                            }}
                            size='small'
                        />
                    </Col>
                </Row>
            </>;


        }
        return (
            <Card
                title='Primary Account'
                loading={this.state.status === 'loading'}
                extra={
                    <>
                        <Input
                            placeholder="search(name/email/phone)..."
                            size="default"
                            onChange={(e: any) => this.onChange(e.target.value)}
                            onPressEnter={(e: Event) => this.onSearch(e)}
                        />
                    </>
                }
            >
                {
                    widgets
                }
            </Card>
        );
    }
}

export default PrimaryAccount;
