import React from 'react';
import { Modal, Button, Card } from 'antd';
import { Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

type validateStatus = "success" | "warning" | "error" | "validating";

interface Props {
    cb: (authorize: boolean) => any;
}
const PasscodeConfirm = (props: Props) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = React.useState(true);
    const [formValidate, setFormValidate] = React.useState("validating" as validateStatus);
    const onFinish = async (values: any) => {
        const { passcode } = values;
        if ((passcode === '0000') ||
            (passcode === '1234') ||
            (passcode === '2468') ||
            (passcode === '1357')) {
            props.cb(true);
            setVisible(false);
        } else {
            setFormValidate('error');
        }
    }
    return (
        <>
            <Modal
                title="Authorize Permission"
                visible={visible}
                closable={false}
                footer={null}
            >
                <Card loading={false}
                    style={{ border: 'none' }}
                >
                    <Form form={form} name="horizontal_host" layout="inline" onFinish={onFinish}>
                        <Form.Item
                            name="passcode"
                            rules={[{ required: true, message: 'Please enter valid passcode!' }]}
                            validateStatus={formValidate}
                            help={formValidate == 'error' ? "please enter correct passcode" : ""}
                        >
                            <Input.Password
                                placeholder="enter authorize passcode"
                                iconRender={(visible: boolean) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}

                            />
                        </Form.Item>


                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !form.isFieldsTouched(true) ||
                                        !!form.getFieldsError().filter((v: any) => v.errors.length).length
                                    }
                                >
                                    authorize
                                </Button>
                            )}
                        </Form.Item>
                    </Form>
                </Card>
            </Modal>
        </>
    );
};
export default PasscodeConfirm;
