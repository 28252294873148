import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBnfir9kTzHTvgK5tMF8Sy_auc2yBm-Cc8",
  authDomain: "df-kirin.firebaseapp.com",
  databaseURL: "https://df-kirin.firebaseio.com",
  projectId: "df-kirin",
  storageBucket: "df-kirin.appspot.com",
  messagingSenderId: "816384609076",
  appId: "1:816384609076:web:41994897ae9785a1a0f361",
  measurementId: "G-82QCRDCNTD"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
