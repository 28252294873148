import { Icon, Layout, Menu } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { MessageOutlined, ToolOutlined, DribbbleOutlined, PartitionOutlined, BarChartOutlined, ShopOutlined, DesktopOutlined } from '@ant-design/icons';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppState, AuthState } from '../store';
import logo from './logo.png';
const { SubMenu } = Menu
const { Sider } = Layout;

const mapStateToProps = ({ authState }: AppState) => {
  return {
    authState,
  };
};
interface P extends RouteComponentProps {
  authState: AuthState;
  onMenuClick?: (item: { key: string }) => void;
  currentMenuItem?: string;
}

class SideNav extends React.Component<P> {
  render() {
    const { onMenuClick, currentMenuItem } = this.props;
    return (
      <Sider
        className="side-nav"
        collapsible={true}
        defaultCollapsed={true}
        style={{ backgroundColor: '#2d373c', color: '#222' }}
      >
        <div className="logo">
          <img alt={'logo'} src={logo} />
          <h2>Admin</h2>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['webPortal']}
          selectedKeys={[currentMenuItem || 'webPortal']}
          onClick={onMenuClick}
        >
          <Menu.Item key="dashboard" icon={<BarChartOutlined />}>Dashboard</Menu.Item>
          <Menu.Item key="monitor" icon={<DesktopOutlined />}>Monitor</Menu.Item>
          <SubMenu
            key="sub-toolkit"
            icon={<ToolOutlined />}
            title={
              <span>
                <Icon type='tool'></Icon>
                <span>Toolkit</span>
              </span>
            }
          >
            <Menu.Item key="webPortal">Controls</Menu.Item>
            <Menu.Item key="mCommerce">M-Commerce</Menu.Item>
            <Menu.Item key="accountClone">Account-Clone</Menu.Item>

          </SubMenu>
          <SubMenu
            key="sub-integration"
            icon={<PartitionOutlined />}
            title={
              <span>
                <Icon type='tool'></Icon>
                <span>Integration</span>
              </span>
            }
          >
            <Menu.Item key="trello" >Trello</Menu.Item>
          </SubMenu>
          <Menu.Item key="host" icon={<DribbbleOutlined />}>Hosting</Menu.Item>
          <Menu.Item key="sms" icon={<MessageOutlined />}>Order SMS</Menu.Item>
          <Menu.Item key="shop" icon={<ShopOutlined />}>Shops</Menu.Item>
        </Menu>
      </Sider>
    );
  }
}
export default withRouter(connect(mapStateToProps, null)(SideNav)) as any;
