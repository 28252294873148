import React, { useState, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { Return } from "../store";
import firebase from 'firebase/app';
interface Props {
    cb: (v: Return) => void,
}
const HorizontalHostForm = (props: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [, forceUpdate] = useState({});
    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate({});
    }, []);

    const onFinish = async (values: any) => {
        setLoading(true)
        const { hostId, merchantId } = values
        const hostfunc = firebase.functions().httpsCallable('hostByAction');
        const result = await hostfunc({
            actionType: 'create',
            hostId,
            merchantId
        });
        const { success, code } = result.data as { success: boolean, data?: any, code?: string }
        setLoading(false)
        props.cb({ success: success, data: { ...values }, message: code })
    };

    return (
        <Form form={form} name="horizontal_host" layout="inline" onFinish={onFinish}>
            <Form.Item
                name="hostId"
                rules={[{ required: true, message: 'Please input your host address!' }]}
            >
                <Input addonBefore='host address' placeholder="xxxx.piticommerce.com" />
            </Form.Item>
            <Form.Item
                name="merchantId"
                rules={[{ required: true, message: 'Please input your merchantId!' }]}
            >
                <Input
                    addonBefore='merchantId'
                    placeholder="Enter merchantId(eg...piticafe)"
                />
            </Form.Item>
            <Form.Item shouldUpdate>
                {() => (
                    loading ? <Button type='primary' loading>loading</Button> :
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                                !form.isFieldsTouched(true) ||
                                !!form.getFieldsError().filter((v: any) => v.errors.length).length
                            }
                        >
                            create
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};

export default HorizontalHostForm;