import { Card, Table, Avatar, Button, Input } from 'antd';
import firebase from 'firebase/app';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import React from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { from, Subscription } from 'rxjs';
import { Shop } from '../store';
import ButtonGroup from 'antd/lib/button/button-group';
const { Search } = Input

const createColummns = (goToDetail: (merchantId: string) => any, searchText: any) => {
    const columns = [
        {
            title: 'Photo',
            dataIndex: 'photo',
            // key: 'merchantId',
            // align: 'right',
            render: (v: number, record: Shop) => < Avatar src={record.photo} size={70} />
        },
        {
            title: 'MerchantId',
            dataIndex: 'merchantId',
            key: 'merchantId',
            // align: 'right',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value: string, record: Shop) => record.merchantId.includes(value),
            ellipsis: true,
            sorter: (a: Shop, b: Shop) =>
                a.merchantId.localeCompare(b.merchantId),
        },
        {
            title: 'Name',
            dataIndex: 'businessName',
            key: 'businessName',
            sorter: (a: Shop, b: Shop) =>
                a.businessName.localeCompare(b.businessName),
        },
        // {
        //     title: 'Email',
        //     dataIndex: 'email',
        //     key: 'email',
        // },
        {
            title: 'Phone No',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Total Orders',
            dataIndex: 'totalCount',
            key: 'totalCount',
            sorter: (a: Shop, b: Shop) => a.totalCount - b.totalCount,
        },
        {
            title: 'Web Orders',
            dataIndex: 'webCount',
            key: 'webCount',
            sorter: (a: Shop, b: Shop) => a.webCount - b.webCount,
        },
        {
            title: 'POS Orders',
            dataIndex: 'posCount',
            key: 'posCount',
            sorter: (a: Shop, b: Shop) => a.posCount - b.posCount,
        },
        {
            title: 'Last Ative',
            dataIndex: 'lastActive',
            key: 'lastActive',
            sorter: (a: Shop, b: Shop) => a.lastActive - b.lastActive,
            render: (v: number, record: Shop) => {
                return moment(v).format('DD/MM/YYYY');
            },
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: '12%',
            fixed: 'right',
            render: (v: number, record: Shop) => {
                return (
                    <ButtonGroup>
                        <Button shape="circle" icon={<EyeOutlined />}
                            onClick={() => goToDetail(record.merchantId)}
                        />
                    </ButtonGroup>
                );

            }
        }
    ];

    return columns
}
type S =
    | { status: 'loading' }
    | {
        status: 'loaded';
        data: Shop[];
        searchText: '',
    };
interface P extends RouteComponentProps {
    goToShop: (id: string) => any;
}
class ShopList extends React.Component<P, S> {
    sub: Subscription;
    constructor(props: P) {
        super(props);
        this.sub = new Subscription();
        this.state = { status: 'loading' };
        this.goToShop = this.goToShop.bind(this);
        this.onSearch = this.onSearch.bind(this)
    }

    componentDidMount() {
        const getAllShops = firebase.functions().httpsCallable('mcommerceGetShops');
        this.sub = from(getAllShops()).subscribe(
            (result) => {
                const { data } = result.data;
                this.setState({
                    status: 'loaded',
                    data: data.shops,
                });
            }
        );
    }
    componentWillUnmount() {
        this.sub.unsubscribe();
    }
    goToShop(merchantId: string) {
        this.props.goToShop(merchantId)
    }
    onSearch = (value: any) => {
        if (this.state.status === 'loaded') {
            this.setState({ ...this.state, searchText: value, })
        }

    }
    render() {
        const data = this.state.status === 'loaded' ? this.state.data : [];
        const searchText = this.state.status === 'loaded' ? this.state.searchText : null;
        return (
            <Card title="Shop List"
                loading={this.state.status === 'loading'}
                extra={
                    <>
                        <Search
                            placeholder="search merchantId..."
                            allowClear
                            enterButton="Search"
                            size="default"
                            onSearch={this.onSearch}
                        />
                    </>
                }
            >
                <Table
                    columns={createColummns(this.goToShop, searchText)}
                    dataSource={data}
                    pagination={{
                        pageSize: 35,
                    }}
                />
            </Card>
        );
    }
}
const mapDispatchToProps = (dispatch: any, { history }: P) => {
    return {
        goToShop: (id: string) => history.push(`/shopDetail/${id}`),
    }
}
export default connect(mapDispatchToProps)(withRouter(ShopList));

