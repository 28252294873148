import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, List, Input, Form, Select } from 'antd';
// import firebase from "firebase/app";
import { getTrelloBoards, getBoardIdList, addTrelloBoardId } from "../api";
import { CopyOutlined, PlusOutlined, CodeOutlined } from '@ant-design/icons';
import { TrelloBoard, TrelloIdList } from "../store";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAlert } from 'react-alert';
// const { TextArea } = Input
const { Option } = Select
const originData: TrelloBoard[] = []
const idOriginData: TrelloIdList[] = [];
const BoardIdList = () => {
    const alert = useAlert();
    const [form] = Form.useForm();
    const [visible, setVisible] = React.useState(false);
    const [state, setState] = useState({ status: 'loading', data: originData })
    const [copyIndex, setCopyIndex] = useState(-1)
    const [openCreate, setOpenCreate] = useState(false);
    const [currentBoard, setCurrentBoard] = useState('');
    const [idList, setIdList] = useState(idOriginData);
    const [loading, setLoading] = useState(false)
    const copied = (i: number) => i === copyIndex;
    useEffect(() => {
        getTrelloBoards().then((result) => {
            if (result.success) {
                const _state = { ...state }
                setState({ ..._state, status: 'loaded', data: result.boards || [] })
            }
        }).catch(error => {
            console.log(error)
        })
    }, [])
    const showModal = () => {
        setVisible(true);
    };
    const handleOk = async () => {
        setVisible(false)

    };
    const handleCancel = () => {
        setVisible(false);
    };
    const onFinish = async (values: any) => {
        setLoading(true)
        const { name } = values
        setState({ ...state, status: 'loading' });
        addTrelloBoardId(currentBoard, name, (success, err, data) => {
            if (success) {
                form.resetFields();
                onBoardChange(currentBoard);
                alert.success("Suceess : Added BoardId")
            }
            setLoading(false)
        });
    }
    const onBoardChange = (boardId: string) => {
        setCurrentBoard(boardId);
        setState({ ...state, status: 'loading' })
        getBoardIdList(boardId).then(res => {
            if (res.success) {
                setIdList(res.list || []);
            }
            setState({ ...state, status: 'loaded' })
        });
    }
    return (
        <>
            <Button
                style={{ marginLeft: 3 }}
                icon={<CodeOutlined />}
                onClick={showModal}>
                show board IdList
            </Button>
            <Modal
                title="Board IdLists"
                visible={visible}
                onOk={handleOk}
                confirmLoading={false}
                onCancel={handleCancel}
            >
                <Card loading={state.status !== 'loaded'}
                    title={
                        <span>
                            <Select
                                showSearch
                                defaultValue={currentBoard}
                                style={{ width: '100%', }}
                                placeholder="select board"
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                onChange={onBoardChange}
                            >
                                {
                                    state.data.map(s => (<Option key={s.name} value={s.id} >{s.name} </Option>))
                                }
                            </Select>
                        </span>
                    }
                    extra={
                        <>
                            {/* <MessageCode /> */}
                            <Button
                                type='primary'
                                icon={<PlusOutlined />}
                                onClick={() => setOpenCreate(!openCreate)}
                                style={{ marginLeft: 12 }}
                            >
                                Add List
                            </Button>
                        </>}
                >
                    {
                        openCreate && <Card title="Create BoardId" type="inner">
                            <Form form={form} name="horizontal_board" onFinish={onFinish}>
                                <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: 'Please input your board name!' }]}
                                    style={{ marginTop: 10 }}
                                >
                                    <Input
                                        addonBefore='name'
                                        placeholder="enter board name...."
                                    />
                                </Form.Item>
                                <Form.Item shouldUpdate style={{ marginTop: 10 }}>
                                    {() => (
                                        loading ? <Button type='primary' loading>loading</Button> :
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={
                                                    !form.isFieldsTouched(true) ||
                                                    !!form.getFieldsError().filter((v: any) => v.errors.length).length
                                                }
                                            >
                                                create
                                            </Button>
                                    )}
                                </Form.Item>
                            </Form>

                        </Card>
                    }

                    <List
                        itemLayout="horizontal"
                        dataSource={idList}
                        bordered={false}
                        split={false}
                        renderItem={(item: TrelloIdList, index: number) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Input value={item.name} disabled={true} />}
                                    title={<Input value={item.id} disabled={true}
                                        addonAfter={
                                            <CopyToClipboard
                                                text={item.id}
                                                onCopy={() => setCopyIndex(index)}
                                            >
                                                <span>
                                                    {
                                                        copied(index) ? <a style={{ color: 'green' }}>Copied!</a> : <CopyOutlined />
                                                    }
                                                </span>
                                            </CopyToClipboard>

                                        } />}
                                />
                            </List.Item>
                        )} />
                </Card>
            </Modal>
        </>
    );
};
export default BoardIdList;
