import moment from 'moment';
import { defer, from, Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { Merchant, Order, Customer, TrelloBoard, TrelloIdList } from './store';

export const baseURL = process.env.REACT_APP_API_URL;
export const serverUrl = 'https://us-central1-df-kirin.cloudfunctions.net'
export const merchantUrl = `${serverUrl}/pos/getMerchant`
export const merchantOrderUrl = `${serverUrl}/posRp/v1/getAllOrder`
export const merchantCustomerUrl = `${serverUrl}/posRp/v1/getAllCustomer`
export const trelloBaseUrl = 'https://api.trello.com/1';
export const trelloApiKey = '8a75942391c82c0d99d64e2f013dae28';
export const trelloTokenKey = '3c1d8b3c24d15230a62f5ac654854d4884cfb0e9eb339478fd423e61cdc227e6';
//setup trello 
var Trello = require("trello");
const trelloApp = new Trello(trelloApiKey, trelloTokenKey);
type GAuthResp = {
  success: boolean,
  userId?: string,
  token?: string,
  errorType: 'invalidIdentityDomain' | 'invalidToken' | 'unknown',
};
type MGetResp = {
  status: 'Ok' | 'error',
  data?: Merchant
  message?: string,
}
type MOrderResp = {
  status: 'Ok' | 'error'
  data: Order[],
}
type MCustomerResp = {
  status: 'Ok' | 'error'
  data: Customer[],
}

export const gauthAsync = async (idToken: string): Promise<GAuthResp> => {
  const resp = await fetch(`https://us-central1-df-kirin.cloudfunctions.net/gauth/?token=${idToken}`);
  if (resp.status >= 200 && resp.status < 300) {
    const r = await resp.json() as GAuthResp;
    return r;
  }
  const text = await resp.text();

  // tslint:disable-next-line:no-console
  console.error(new Error(`status: ${resp.status}, body: ${text}`));

  return {
    success: false,
    errorType: 'unknown',
  };
};
export const getMerchant = async (merchantId: string) => {
  const resp = await fetch(`${merchantUrl}/${merchantId}`);
  if (resp.status >= 200 && resp.status < 300) {
    const r = await resp.json() as MGetResp;
    if (r.status === 'Ok') {
      return { success: true, merchant: r.data }
    }
    return { success: false, message: r.message }
  }
  const text = await resp.text();

  // tslint:disable-next-line:no-console
  console.error(new Error(`status: ${resp.status}, body: ${text}`));

  return {
    success: false,
    errorType: 'unknown',
  };
}
export const getOrders = async (merchantId: string) => {
  const resp = await fetch(`${merchantOrderUrl}/${merchantId}`);
  if (resp.status >= 200 && resp.status < 300) {
    const r = await resp.json() as MOrderResp;
    if (r.status === 'Ok') {
      return { success: true, orders: r.data }
    }
    return { success: false, message: 'Invalid Orders' }
  }
  const text = await resp.text();

  // tslint:disable-next-line:no-console
  console.error(new Error(`status: ${resp.status}, body: ${text}`));

  return {
    success: false,
    errorType: 'unknown',
  };
}
export const getCustomers = async (merchantId: string) => {
  const resp = await fetch(`${merchantCustomerUrl}/${merchantId}`);
  if (resp.status >= 200 && resp.status < 300) {
    const r = await resp.json() as MCustomerResp;
    if (r.status === 'Ok') {
      return { success: true, customers: r.data }
    }
    return { success: false, message: 'Invalid Orders' }
  }
  const text = await resp.text();

  // tslint:disable-next-line:no-console
  console.error(new Error(`status: ${resp.status}, body: ${text}`));

  return {
    success: false,
    errorType: 'unknown',
  };
}
export const getTrelloBoards = async () => {
  // https://api.trello.com/1/members/me/boards?fields=name,url&key=&token=
  const resp = await fetch(`${trelloBaseUrl}/members/me/boards?fields=name,url&key=${trelloApiKey}&token=${trelloTokenKey}`);
  if (resp.status >= 200 && resp.status < 300) {
    const boards = await resp.json() as TrelloBoard[];
    return { success: true, boards }

  }
  const text = await resp.text();
  // tslint:disable-next-line:no-console
  console.error(new Error(`status: ${resp.status}, body: ${text}`));

  return {
    success: false,
    errorType: 'unknown',
  };
}
export const addTrelloBoard = async (name: string, desc: string, cb: (success: boolean, errorType?: any, data?: any) => void) => {
  trelloApp.addBoard(name, desc, null, (err: any, board: any) => {
    if (err) {
      //console.log("error", err)
      cb(false, err, null)
      // return {
      //   success: false,
      //   errorType: 'unknown',
      // };
    } else {
      cb(true, null, board)
      // console.log("add", board)
      // return {
      //   success: true,
      //   data: board,
      // };
    }
    return;
  })
}
export const addTrelloBoardId = async (boardId: string, name: string, cb: (success: boolean, errorType?: any, data?: any) => void) => {
  trelloApp.addListToBoard(boardId, name, (err: any, list: any) => {
    if (err) {
      //  console.log("error", err)
      cb(false, err, null);
    } else {
      // console.log("add ", err)
      cb(true, null, list);
    }
    return;
  })
}
export const getBoardIdList = async (boardId: string) => {
  const resp = await fetch(`${trelloBaseUrl}/boards/${boardId}/lists?key=${trelloApiKey}&token=${trelloTokenKey}`);
  if (resp.status >= 200 && resp.status < 300) {
    const list = await resp.json() as TrelloIdList[];
    return { success: true, list }
  }
  const text = await resp.text();
  // tslint:disable-next-line:no-console
  console.error(new Error(`status: ${resp.status}, body: ${text}`));

  return {
    success: false,
    errorType: 'unknown',
  };
}


// tslint:disable-next-line:no-any
function toQueryString(params: any) {
  return Object.keys(params)
    .filter((k) => params[k])
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join('&');
}

const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;
// tslint:disable-next-line
const reviver = (key: any, value: any) => {
  if (typeof value === 'string' && iso8601Regex.test(value)) {
    return moment(value);
  } else {
    return value;
  }
};

export const fetchJSON$ = <T>(
  url: string,
  payload: T,
  method: string = 'POST'
) => {
  const headers = {};
  let rURL = baseURL + url;
  if (method === 'GET') {
    rURL += `?${toQueryString(payload)}`;
  }
  return from(
    fetch(rURL, {
      method,
      headers,
      body:
        method === 'POST' || method === 'PUT'
          ? JSON.stringify(payload)
          : undefined,
    })
  ).pipe(
    flatMap((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        return resp.text().then((text) => {
          return text ? JSON.parse(text, reviver) : undefined;
        });
      } else if (resp.status === 404) {
        return defer(() =>
          of({
            status: '404',
          })
        );
      } else {
        return from(resp.text()).pipe(
          map((err) => ({
            status: 'error',
            error: err,
          }))
        );
      }
    })
  );
};

export const imgPath$ = (key: string): Observable<string> =>
  fetchJSON$('/downloadURL', { key }, 'POST').pipe(map((r) => r.location));

export const statusColors = {
  Draft: {
    color: 'blue',
    iconName: 'save',
  },
  Submitted: {
    color: '#108ee9',
    iconName: 'solution',
  },
  Resubmit: {
    color: '#f50',
    iconName: 'exception',
  },
  Processing: {
    color: '#ff9900',
    iconName: 'file-sync',
  },
  Recommended: {
    color: '#33aa33',
    iconName: 'file-done',
  },
  default: {
    color: '#2db7f5',
    iconName: 'solution',
  },
};
