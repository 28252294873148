import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, List, Input, InputNumber } from 'antd';
import firebase from "firebase/app";
import { CarOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { ShippingFee } from "./state";
import { useAlert } from 'react-alert';
interface Props {
    merchantId: string;
}
const originData: ShippingFee[] = []
const ShippingFees = (props: Props) => {
    const alert = useAlert()
    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [state, setState] = useState({ status: 'loading', data: originData })
    useEffect(() => {
        const getAllHosts = firebase.functions().httpsCallable('getAllShippingFee');
        getAllHosts({ merchantId: props.merchantId }).then((result) => {
            const { status, data } = result.data as { status: 'success' | 'invalid', data?: any }
            if (status === 'success') {
                const fees = data as ShippingFee[]
                const _state = { ...state }
                setState({ ..._state, status: 'loaded', data: fees })
            }
        }).catch(error => {
            console.log(error)
        })
    }, [])
    const showModal = () => {
        setVisible(true);
    };
    const handleOk = async () => {
        setConfirmLoading(true);
        const feefunc = firebase.functions().httpsCallable('shipingFeeByAction');
        const result = await feefunc({
            actionType: 'update',
            merchantId: props.merchantId,
            data: { list: state.data }
        });
        const { success, code } = result.data as { success: boolean, data?: any, code?: string }
        if (success) {
            alert.success(`success:${props.merchantId}`)
            setVisible(false);
            setConfirmLoading(false);
        } else {
            alert.error(`Failed: ${props.merchantId} $${code}`)
            setConfirmLoading(false)
        }
    };

    const handleCancel = () => {
        setVisible(false);
    };
    const rmFee = (index: number) => {
        setState({ ...state, data: state.data.filter((v: any, i: number) => i !== index) })
    }
    const onChange = (index: number, key: string, v: any) => {
        let _news = [...state.data]
        _news[index] = { ..._news[index], [key]: v }
        setState({ ...state, data: _news })
    }
    const addFee = () => {
        let _news = [...state.data]
        _news.unshift({ amount: 0, location: '' })
        setState({ ...state, data: _news })
    }
    return (
        <>
            <Button icon={<CarOutlined />} onClick={showModal}>
                shipping fees
            </Button>
            <Modal
                title={
                    <span>
                        Shipping Fees
                    <Button
                            icon={<PlusOutlined />}
                            onClick={() => addFee()}
                            disabled={state.status !== 'loaded'}
                            style={{ marginLeft: 12 }}
                        >
                            Add Fee
                        </Button>
                    </span>
                }
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Card loading={state.status !== 'loaded'}
                >
                    <List
                        itemLayout="horizontal"
                        dataSource={state.data}
                        bordered={false}
                        split={false}
                        renderItem={(item: any, index: number) => (
                            <List.Item
                                extra={
                                    <Button shape="circle" icon={<CloseOutlined />} onClick={() => rmFee(index)} />
                                }
                            >
                                <List.Item.Meta
                                    avatar={<Input placeholder="enter shipping location" value={item.location} onChange={(e: any) => onChange(index, 'location', e.target.value)} />}
                                    title={<InputNumber placeholder='enter shipping amount' value={item.amount} onChange={(v: number) => onChange(index, 'amount', v)} />}
                                />
                            </List.Item>
                        )} />
                </Card>
            </Modal>
        </>
    );
};
export default ShippingFees;
