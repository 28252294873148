import { Card, Row, Col, Table } from 'antd';
import React from 'react';
import { ShopSummary, defaultShopSummary } from './state'
import { formatMoney } from "../utils";
import moment from 'moment'
const { Meta } = Card

const columns = [
    {
        title: 'Event',
        dataIndex: 'name',
    },
    {
        title: 'Value',
        dataIndex: 'age',
    },
    {
        title: 'Last Active',
        dataIndex: 'address',
    },
];

const rows = (data: ShopSummary) => {
    const rows = [
        {
            key: '1',
            name: 'Total Orders',
            age: data.completedCount + data.cancelledCount + data.pendingCount,
            address: moment(data.lastActive).format("DD MMM YYYY hh:mm a"),
        },
        {
            key: '2',
            name: 'Web Completed Orders',
            age: data.webCompletedCount,
            address: moment(data.lastActive).format("DD MMM YYYY hh:mm a"),
        },
        {
            key: '3',
            name: 'Web Pending Orders',
            age: data.webPendingCount,
            address: moment(data.lastActive).format("DD MMM YYYY hh:mm a"),
        },
        {
            key: '4',
            name: 'Web Cancel Orders',
            age: data.webCancelledCount,
            address: moment(data.lastActive).format("DD MMM YYYY hh:mm a"),
        },
        {
            key: '5',
            name: 'POS Orders',
            age: data.posCompletedCount,
            address: moment(data.lastActive).format("DD MMM YYYY hh:mm a"),
        },
        {
            key: '6',
            name: 'Total Sales',
            age: formatMoney(data.completedAmount),
            address: moment(data.lastActive).format("DD MMM YYYY hh:mm a"),
        },
    ];
    return rows
}

interface P {
    data: ShopSummary,
    loading: boolean,
}
type S = {
    data: ShopSummary,
    loading: boolean
}

class ShopDetailDashboard extends React.Component<P, S> {
    constructor(props: P) {
        super(props)
        this.state = { data: { ...defaultShopSummary }, loading: false };
    }
    componentDidMount() {
        const { data, loading } = this.props
        console.log()
        this.setState({ data, loading })
    }
    componentWillReceiveProps(nextProps: P) {
        const { data, loading } = nextProps
        this.setState({ data, loading })
    }
    render() {
        const { data } = this.state
        return (
            <>
                <Row gutter={24}>
                    <Col span={8} >
                        <Card
                            style={{ width: 400, boxShow: 'none', border: 'none' }}
                            cover={
                                <img
                                    alt="example"
                                    src={data.businessPhoto ? data.businessPhoto : "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"}
                                />
                            }
                        >
                            <Meta title={data.businessName} description={`https://${data.merchantId}.piticommerce.com`} />
                            <Row gutter={24} style={{ marginTop: 5 }}>
                                <Col span={6}>
                                    Status
                                </Col>
                                <Col span={18}>
                                    Online
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={6}>
                                    Phone
                                </Col>
                                <Col span={18}>
                                    {data.businessPhone}
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={6}>
                                    Address
                                </Col>
                                <Col span={18}>
                                    {data.businessAddress}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={16} >
                        {/* <Card
                            loading={this.state.loading}
                        > */}
                        <Table
                            columns={columns}
                            dataSource={rows(data)}
                            size="middle"
                            pagination={{
                                hideOnSinglePage: true,
                            }}
                        />
                        {/* </Card> */}
                    </Col>
                </Row>
            </>
        );
    }
}

export default ShopDetailDashboard;
