import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Table, Button, Avatar, Card, Input, List, Select, Typography } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import firebase from 'firebase/app';
import { withRouter, RouteComponentProps } from 'react-router';
import ButtonGroup from 'antd/lib/button/button-group';
import { Shop, Item, ItemCategory, EcommerceItemSetup, StockItems } from '../../store';
import VariatonOptionForm from './format_variations';
import { useAlert } from 'react-alert';
const { Search } = Input
const { Option } = Select;
const { Text } = Typography
const createColummns = (goToEdit: (item: Item) => any, searchText: any) => {
    const columns = [
        {
            title: 'Photo',
            dataIndex: 'photo',
            render: (v: number, record: Shop) => < Avatar src={record.photo} size={70} />
        },
        {
            title: 'ItemId',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            sorter: (a: Item, b: Item) =>
                a.id.localeCompare(b.id),
        },
        {
            title: 'Status',
            key: 'estatus',
            sorter: (a: Item, b: Item) =>
                (a.estatus || 'incomplete').localeCompare((b.estatus || 'incomplete')),
            render: (v: Item, record: Item) => {
                return (v.estatus && v.estatus === 'complete') ? <Text type="success">{v.estatus}</Text> : <Text type="warning">incomplete</Text>
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value: string, record: Item) => record.name.toLocaleLowerCase().includes(value),
            sorter: (a: Item, b: Item) =>
                a.name.localeCompare(b.name),
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (v: ItemCategory, record: Item) => {
                return (v && v.name) ? v.name : "";
            },
        },
        {
            title: 'Variations',
            dataIndex: 'itemVariationIds',
            key: 'itemVariationIds',
            sorter: (a: Item, b: Item) =>
                a.itemVariationIds.length - b.itemVariationIds.length,
            render: (v: string[], record: Item) => {
                return (v) ? v.length : 0;
            },
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: '12%',
            fixed: 'right',
            render: (v: number, record: Item) => {
                return (
                    <ButtonGroup>
                        <Button shape="circle" icon={<EditOutlined />}
                            onClick={() => goToEdit(record)}
                        />
                    </ButtonGroup>
                );

            }
        }
    ];

    return columns
}
interface P extends RouteComponentProps {
    goToItems: (id: string) => any;
}
const initItems: Item[] = [];
const initCurrentItem: Item | null = null;
const initVariations: Array<{ used: boolean, id: string }> | null = null;
const initESetupItems: EcommerceItemSetup[] = [];
const initCbdata: { items: EcommerceItemSetup[], pairs: [] } | null = null;
const initStockItems: StockItems[] = [];
function ItemList(props: P) {
    const alert = useAlert();
    const { merchantId } = props.match.params as { merchantId: string }
    //const [form] = Form.useForm();
    const [, forceUpdate] = useState({});
    const [showVariationForm, setShowVariationForm] = useState(false);
    const [state, setState] = useState({ status: 'loading', data: initItems })
    const [currentItem, setCurrentItem] = useState(initCurrentItem);
    const [searchText, setSearchText] = useState("");
    const [tempVars, setTempVars] = useState(initVariations);
    const [loading, setLoading] = useState(false);
    const [setUpItems, setSetUpItems] = useState(initESetupItems);
    const [stockItems, setStockItems] = useState(initStockItems);

    const [cbData, setCbData] = useState(initCbdata);

    useEffect(() => {
        forceUpdate({});
        if (merchantId) {
            firebase.firestore().collection(`merchants/${merchantId}/items`).get().then(res => {
                if (!res.empty) {
                    const _items: Item[] = [];
                    for (const doc of res.docs) {
                        _items.push({ id: doc.id, ...doc.data() } as Item)
                    }
                    setState({ status: 'loaded', data: _items });
                }
            }).catch(err => {
                console.log(err)
            })
        }

    }, [merchantId]);
    const clearState = () => {
        setCurrentItem(null);
        setStockItems([]);
        setSetUpItems([]);
        setCbData(null);
    }
    const onSearch = (text: any) => {
        if (state.status === 'loaded') {
            setSearchText(text);
        }
    }
    const onEditItem = (v: Item) => {
        setCurrentItem(v);
        let _temps = v.itemVariationIds.map(varId => { return { used: false, id: varId } }) || [];
        if (v.estock) {
            for (const es of v.estock) {
                const finder = _temps.findIndex(v => v.id === es.id);
                if (finder !== -1) {
                    _temps[finder].used = true;

                }
            }
        }
        setTempVars(_temps);
        setSetUpItems(v.esetup || []);
        setStockItems(v.estock || [])
        setCbData({ items: v.esetup || [], pairs: [] })
        setShowVariationForm(false);
    }
    const onEditSubmit = async () => {
        if (currentItem && setUpItems.length > 0 && stockItems.length > 0) {
            const sitems = [...setUpItems];
            setLoading(true);
            const itemRef = firebase.firestore().doc(`merchants/${merchantId}/items/${currentItem.id}`);
            try {
                await itemRef.set({
                    estatus: "complete",
                    esetup: sitems,
                    estock: stockItems,
                }, { merge: true });
                alert.success(`Success setup item ${currentItem.name}`);
                clearState();
            } catch (error) {
                console.log(error)
                alert.success(`Failed setup item ${currentItem.name}`)
            }
            setLoading(false);
        }
    }
    // const onEditChanges = (varIndex: number, index: number, name: string, value: any) => {
    //     const _setitems = [...setUpItems];
    //     let varId: string | null = null;
    //     if (name === "add") {
    //         const options = _setitems[varIndex].options;
    //         options.push({ name: "", id: "" });
    //     } else if (name === "remove") {
    //         const options = _setitems[varIndex].options
    //         varId = _setitems[varIndex].options[index].id;
    //         const rm = options.filter((v: any, i: number) => i !== index);
    //         _setitems[varIndex].options = rm;
    //     } else {
    //         const options = _setitems[varIndex].options;
    //         options[index][name] = value.target.value;
    //         if (name === "id") {
    //             varId = value.target.value;
    //             let optionName = value.target.value;
    //             if (typeof optionName === 'string') {
    //                 optionName = optionName.split('->').length === 2 ? optionName.split('->')[1] : optionName;
    //             }
    //             options[index]["name"] = optionName;
    //         }
    //     }
    //     if (varId && tempVars) {
    //         const finder = tempVars.findIndex(v => v.id === varId);
    //         if (finder !== -1) {
    //             tempVars[finder].used = name === 'remove' ? false : true;
    //         }
    //     }
    //     setSetUpItems(_setitems);
    // }
    const onStockChanges = (index: number, varId: string) => {
        const _stockItems = [...stockItems];
        const _items = [...setUpItems];
        _stockItems[index].id = varId;
        const connectId = _stockItems[index].connectorId;
        _items.forEach((item: EcommerceItemSetup, itemIndex: number) => {
            const finder = item.options.findIndex(v => v.connectVarIds && v.connectVarIds.includes(connectId))
            let sIds: string[] = [];
            if (finder !== -1) {
                sIds = _items[itemIndex].options[finder].stockVarIds || [];
                sIds.push(varId);
                _items[itemIndex].options[finder].stockVarIds = Array.from(new Set(sIds));
            }
        });
        if (tempVars) {
            const finder = tempVars.findIndex(v => v.id === varId)
            if (finder !== -1) {
                tempVars[finder].used = true;
            }
        }
        setSetUpItems(_items);
        setStockItems(_stockItems);
    }
    const OnEditForm = () => {
        return (
            <div>
                <div>
                    {(currentItem && currentItem.itemVariationIds.length > 0) && <div>
                        <ol>
                            {
                                currentItem.itemVariationIds.map(iv => (<li>{iv}</li>))
                            }

                        </ol>
                    </div>}
                </div>
                <div>
                    {(tempVars && tempVars.length > 0 && stockItems.length > 0) && <List
                        itemLayout="horizontal"
                        dataSource={stockItems}
                        bordered={false}
                        split={false}
                        renderItem={(item: StockItems, index: number) => (
                            <List.Item
                                style={{ width: '70%' }}
                            // extra={
                            //     <Button shape="circle" icon={<CloseOutlined />} onClick={() => onStockChanges(index, 'remove', null)} />
                            // }
                            >
                                <List.Item.Meta
                                    title={<Text type="info">{item.name}</Text>}
                                    avatar={
                                        <Select
                                            value={item.id}
                                            placeholder="select option"
                                            style={{ width: 300, margin: '0 8px' }}
                                            onChange={(v: string) => onStockChanges(index, v)}
                                        >
                                            {
                                                tempVars.filter(tv => tv.used === false).map(t => <Option value={t.id}>{t.id}</Option>
                                                )
                                            }
                                        </Select>
                                    }
                                />
                            </List.Item>
                        )} />
                    }
                </div>
                {currentItem &&
                    < Button disabled={loading} style={{ marginTop: 5 }} onClick={onEditSubmit} >{loading ? 'loading...' : 'save'}</Button>
                }
            </div >
        );
    }
    const cb = (data: typeof initCbdata) => {
        if (tempVars) {
            let _temps = tempVars.map((v) => { return { ...v, used: false } });
            setTempVars(_temps);
        }
        setCbData(data);
        setShowVariationForm(false);

        if (data) {
            const _items = [...data.items];
            const _stockItems: StockItems[] = [];
            data.pairs.forEach(pair => {
                const fVar: string = pair[0], sVar: string = pair[1];
                //  const fVarName = fVar.split('->')[0];
                const fOptionName = fVar.split('->')[1]
                //const sVarName = sVar.split('->')[0];
                const sOptionName = sVar.split('->')[1]
                const connectId = [fVar, sVar].join('_');
                const stockId = "", stockName = `${fOptionName},${sOptionName}`;
                _stockItems.push({ id: stockId, connectorId: connectId, name: stockName });
                _items.forEach((item: EcommerceItemSetup, index: number) => {
                    const finder = item.options.findIndex(ov => (ov.name === fOptionName) || (ov.name === sOptionName));
                    if (finder !== -1) {
                        const connectors = _items[index].options[finder].connectVarIds || [];
                        connectors.push(connectId);
                        _items[index].options[finder].connectVarIds = connectors;
                    }
                });
            });
            setSetUpItems(_items);
            setStockItems(_stockItems);
        }
    }
    return (
        <Card title="Items"
            loading={state.status !== 'loaded'}
            extra={
                <>
                    <Search
                        placeholder="search item..."
                        allowClear
                        enterButton="Search"
                        size="default"
                        onSearch={onSearch}
                    />
                </>
            }
        >
            {
                (currentItem && showVariationForm) && (
                    <Card
                        type="inner"
                    //  title={`Add new variation for (${currentItem ? currentItem.name : ""} )`}
                    >
                        <VariatonOptionForm
                            cb={cb}
                            item={currentItem}
                        />
                    </Card>
                )
            }
            {
                cbData && <div>
                    {cbData.items.map(v => {
                        return (
                            <div>
                                <h4>{v.variationName}</h4>
                                <ol>
                                    {v.options.map(vo => <li>{vo.name}</li>)}
                                </ol>
                            </div>
                        )
                    })}
                </div>
            }
            {
                (currentItem && !showVariationForm) && (
                    <Card
                        type="inner"
                        title={cbData ? `Setup Ecommerce Item(${currentItem.name})` : `Setup Ecommerce stockItems (${currentItem.name})`}
                        extra={
                            <>
                                <Button
                                    type='primary'
                                    icon={<PlusOutlined />}
                                    onClick={() => setShowVariationForm(!showVariationForm)}
                                >
                                    Setup Variation
                                </Button>
                            </>
                        }
                    >
                        <OnEditForm />
                    </Card>
                )
            }
            <Card type={currentItem ? "inner" : ""}>
                <Table
                    columns={createColummns(onEditItem, searchText)}
                    dataSource={state.data}
                    pagination={{
                        pageSize: 50,
                    }}
                />
            </Card>
        </Card >
    );
}
const mapDispatchToProps = (dispatch: any, { history }: P) => {
    return {
        goToItems: (merchantId: string) => history.push(`/items/${merchantId}`),
    }
}
export default connect(mapDispatchToProps)(withRouter(ItemList));
