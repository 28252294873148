import React, { Component } from 'react';
// const iframe =
//   '<iframe src="https://datastudio.google.com/embed/reporting/d436ec60-5140-4ef3-b25f-43ed4cbd42af/page/NQQQB" scrolling="no" frameborder="0" allowfullscreen style="width:100%; height: 100%; border: none; overflow: hidden"></iframe>';
// https://datastudio.google.com/u/0/reporting/33d01708-738a-4f00-9942-4d53395532f4/page/RxPYC

const iframe2 =
  '<iframe src="https://datastudio.google.com/embed/reporting/33d01708-738a-4f00-9942-4d53395532f4/page/RxPYC" scrolling="no" frameborder="0" allowfullscreen style="width:100%; height: 100%; border: none; overflow: hidden"></iframe>';
interface Props {
  iframe: string;
}

export class MyFrame extends React.Component<Props, {}> {
  iframe() {
    return {
      __html: this.props.iframe,
    };
  }

  render() {
    return (
      // <div style={{ height: '100vh', overflow: 'hidden', position:'absolute'}}>
      <div
        style={{ height: '100%', overflow: 'hidden' }}
        dangerouslySetInnerHTML={this.iframe()}
      />
      // </div>
    );
  }
}

export default class Dashboard extends Component {
  render() {
    return <MyFrame iframe={iframe2} />;
  }
}
