import * as React from 'react'
import { connect } from "react-redux";
import firebase from 'firebase/app';
import { RouteComponentProps, withRouter } from "react-router";
import { Card, Row, Col, Button } from "antd";
import { Subscription, from } from 'rxjs';
import { defaultShopSummary } from "../store";
import ShopDetailSummary from './shopSummaryForm';
import ShopOrderList from './shopOrderList2';
import CustomerList from './shopCustomerList';
import { ShopSummary } from "../store";

interface Props extends RouteComponentProps {
    data?: any;
    goToItems: (id: string) => any;
}

type S =
    | { status: 'loading' }
    | {
        status: 'loaded';
        data: ShopSummary;
    };
class ShopDetail extends React.Component<Props, S> {
    sub: Subscription;

    constructor(props: Props) {
        super(props);
        this.sub = new Subscription();
        this.state = { status: 'loading' };
    }
    componentDidMount() {
        const { id: merchantId } = this.props.match.params as { id: string }
        const getAllShopSummary = firebase.functions().httpsCallable('mcommerceSummaryDashboard');
        this.sub = from(getAllShopSummary({})).subscribe(
            (result) => {
                let { status, data } = result.data as { status: 'success' | 'invalid', data?: { shopList: any[], shopData: ShopSummary[] } };
                if (status === 'success') {
                    let _data: ShopSummary[] = [];
                    if (data) {
                        _data = data.shopData
                    }
                    const index = _data.findIndex(s => s.merchantId === merchantId)
                    const shop = index > -1 ? _data[index] : defaultShopSummary
                    this.setState({
                        status: 'loaded',
                        data: shop,
                    });
                }

            }
        );
    }
    componentWillUnmount() {
        this.sub.unsubscribe();
    }
    render() {
        const loading = this.state.status !== 'loaded'
        const { id: merchantId } = this.props.match.params as { id: string }
        const data = this.state.status === 'loaded' ? this.state.data : defaultShopSummary
        return (
            <Card title='Shop Detail'
                loading={loading}
                extra={<Button onClick={() => this.props.goToItems(merchantId)}>Items</Button>}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <ShopDetailSummary data={data} />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <ShopOrderList merchantId={merchantId} />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <CustomerList merchantId={merchantId} />
                    </Col>
                </Row>
            </Card >
        )
    }
}
const mapDispatchToProps = (dispatch: any, { history }: Props) => {
    return {
        goToItems: (merchantId: string) => history.push(`/items/${merchantId}`),
    }
}

export default connect(mapDispatchToProps)(withRouter(ShopDetail)) as any;