import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as rformReducer } from 'redux-form';
import { createEpicMiddleware } from 'redux-observable';
import { reducers as authReducers } from '../auth';
import epic from '../epic';

// redux
const reduxCombineReducers: any = combineReducers; //tslint:disable-line
const reducer = reduxCombineReducers({
  authState: authReducers.authReducer,
  form: rformReducer,
});

// class RWindow extends Window {
//   __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
// }

// const rwindow = window as RWindow;
const composeEnhancers =
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

const epicMiddleware = createEpicMiddleware();

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(epicMiddleware))
);
epicMiddleware.run(epic);
export * from './state';
export { store };
