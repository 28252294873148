
export interface UrlToMerchant {
    hostId: string;
    merchantId: string;
}

export interface ShopSummary {
    merchantId: string;
    totalCount: number;
    businessName: string;
    businessAddress: string;
    businessPhoto: string;
    businessPhone: string;
    lastActive: number;
    pendingCount: number;
    completedCount: number;
    cancelledCount: number;
    pendingAmount: number;
    completedAmount: number;
    cancelledAmount: number;
    webPendingCount: number;
    webCompletedCount: number;
    webCancelledCount: number;
    posCompletedCount: number;
}
export const defaultShopSummary: ShopSummary = {
    merchantId: '',
    totalCount: 0,
    businessName: '',
    businessAddress: '',
    businessPhoto: '',
    businessPhone: '09',
    lastActive: 0,
    pendingCount: 0,
    completedCount: 0,
    cancelledCount: 0,
    pendingAmount: 0,
    completedAmount: 0,
    cancelledAmount: 0,
    webPendingCount: 0,
    webCompletedCount: 0,
    webCancelledCount: 0,
    posCompletedCount: 0,
}