import React, { useState, useEffect } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Card } from 'antd';
import { Button } from 'antd';
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { useAlert } from 'react-alert';
import firebase from 'firebase/app';
import CreateHost from './createHost'
import { Return } from '../tools/state';
import Item from 'antd/lib/list/Item';
const { Search } = Input
interface Item {
    key: string;
    hostId: string;
    merchantId: string;
}
const originData: Item[] = [];
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: Item;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const disable = dataIndex === 'hostId'
    const inputNode = inputType === 'number' ? <InputNumber disabled={disable} /> : <Input disabled={disable} />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const EditableTable = () => {
    const alert = useAlert();
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [deleteKey, setDeleteKey] = useState('')
    const [openCreate, setOpenCreate] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [state, setState] = useState({ status: 'loading', data: originData, saveLoading: false })
    const isEditing = (record: Item) => record.key === editingKey;
    const isDeleting = (record: Item) => record.key === deleteKey;
    useEffect(() => {
        const getAllHosts = firebase.functions().httpsCallable('getAllHosts');
        getAllHosts().then((result) => {
            const { status, data } = result.data as { status: 'success' | 'invalid', data?: any }
            if (status === 'success') {
                const hostList = data as Array<{ hostId: string, merchantId: string }>
                const formatData = hostList.map((h, index) => {
                    return {
                        key: index.toString(),
                        hostId: h.hostId,
                        merchantId: h.merchantId
                    } as Item
                })
                const _state = { ...state }
                setState({ ..._state, status: 'loaded', data: formatData })
            }
        }).catch(error => {
            console.log(error)
        })
    }, [])
    const edit = (record: Partial<Item> & { key: React.Key }) => {
        form.setFieldsValue({ host: '', merchantId: '', ...record });
        setEditingKey(record.key);
    };
    const onCreate = (v: Return) => {
        if (v.success) {
            setOpenCreate(false)
            let _news = [...state.data]
            const key = (_news.length + 1).toString();
            _news.unshift({ key, merchantId: v.data.merchantId, hostId: v.data.hostId })
            setState({ ...state, data: _news });
            alert.success('success')
        } else {
            alert.error(`Failed: ${v.message}`)
        }
    }
    const cancel = () => {
        setEditingKey('');
    };
    const onDelete = async (v: Item) => {
        setDeleteKey(v.key)
        const hostfunc = firebase.functions().httpsCallable('hostByAction');
        const result = await hostfunc({
            actionType: 'delete',
            hostId: v.hostId,
            merchantId: v.merchantId,
        });
        const { success, code } = result.data as { success: boolean, data?: any, code?: string }
        if (success) {
            alert.success(`success: delete ${v.hostId}`)
            setState({ ...state, data: state.data.filter(b => b.key !== v.key) });
        } else {
            alert.error(`failed : delete ${v.hostId} ${code}`)
        }
        setDeleteKey('');
    }
    const save = async (key: React.Key) => {
        setState({ ...state, saveLoading: true })
        try {
            const row = (await form.validateFields()) as Item;
            const hostfunc = firebase.functions().httpsCallable('hostByAction');
            const result = await hostfunc({
                actionType: 'update',
                hostId: row.hostId,
                merchantId: row.merchantId
            });
            const { success, code: Error } = result.data as { success: boolean, data: any, code?: string }
            if (success) {
                const newData = [...state.data];
                const index = newData.findIndex(item => key === item.key);
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, {
                        ...item,
                        ...row,
                    });
                } else {
                    newData.push(row);
                }
                setState({ ...state, data: newData, saveLoading: false });
                setEditingKey('');
                alert.success(`success: ${row.hostId} `)
            } else {
                alert.error(`failed:${row.hostId} => ${Error}`)
                setState({ ...state, saveLoading: false })
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
            setState({ ...state, saveLoading: false })
        }
    };
    const onSearch = (value: any) => setSearchText(value);
    const columns = [
        {
            title: 'Host',
            dataIndex: 'hostId',
            //width: '25%',
            editable: true,
        },
        {
            title: 'merchantId',
            dataIndex: 'merchantId',
            //width: '15%',
            editable: true,
            filteredValue: [searchText],
            onFilter: (value: string, record: Item) => record.merchantId.includes(value),
            ellipsis: true,
        },

        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_: any, record: Item) => {
                const editable = isEditing(record);
                return editable ? (
                    state.saveLoading ? <Button type='primary' loading >loading</Button> :
                        <span>
                            <Popconfirm title={`Sure to save ${record.hostId}?`} onConfirm={() => save(record.key)}>
                                {/* <a href="javascript:;" onClick={() => save(record.key)} style={{ marginRight: 8 }}>
                                Save
                          </a> */}
                                <a style={{ marginRight: 8 }}>Save</a>
                            </Popconfirm>
                            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                                <a style={{ color: 'red' }}>Cancel</a>
                            </Popconfirm>
                        </span>
                )
                    : (
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            <EditOutlined />  Edit
                        </Typography.Link>
                    );
            },
        },
        {
            title: 'action',
            //width: '25%',
            dataIndex: 'action',
            render: (_: any, record: Item) => {
                const deleting = isDeleting(record)
                return (
                    deleting ? <Button type='primary' loading>deleting...</Button> :
                        <Popconfirm title={`Sure to delete ${record.hostId}?`} onConfirm={() => onDelete(record)}>
                            <Button icon={<DeleteOutlined />} danger >delete</Button>
                        </Popconfirm>
                );
            }
        },
    ];

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Item) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    return (
        <Card
            title="Hosting"
            loading={state.status === 'loading'}
            extra={<Button  type='primary' icon={<PlusOutlined />} onClick={() => setOpenCreate(!openCreate)}>Add Host</Button>}
        >
            {
                openCreate && (
                    <Card type="inner" title="Create host">
                        <CreateHost cb={onCreate} />
                    </Card>
                )
            }

            <Card type={openCreate ? "inner" : ""}
                title="Host list"
                style={{ marginTop: openCreate ? 16 : 0 }}
                extra={
                    <>
                        <Search
                            placeholder="search merchantId..."
                            allowClear
                            enterButton="Search"
                            size="default"
                            onSearch={onSearch}
                        />
                    </>
                }
            >
                <Form form={form} component={false}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        bordered
                        dataSource={state.data}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: cancel,
                        }}
                    />
                </Form>
            </Card>

        </Card>
    );
};

export default EditableTable;