import { Card, Table, Button, Input, Popconfirm, Spin } from 'antd';
import firebase from 'firebase/app';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import React from 'react';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { from, Subscription } from 'rxjs';
import { Return, Shop, TrelloSetting } from '../store';
import ShowBoardList from './addBoard';
import ShowBoardIdList from './addBoardList';
import AddIntegration from './addIntegration';
import UpdateIntegration from './updateIntegration';
import ButtonGroup from 'antd/lib/button/button-group';
const { Search } = Input

const createColummns = (onEdit: (merchantId: TrelloSetting) => any, remove: (merchantId: string) => any, searchText: any) => {
  const columns = [
    {
      title: 'MerchantId',
      dataIndex: 'merchantId',
      key: 'merchantId',
      filteredValue: searchText ? [searchText] : null,
      onFilter: (value: string, record: Shop) => record.merchantId.includes(value),
      ellipsis: true,
      sorter: (a: TrelloSetting, b: TrelloSetting) =>
        a.merchantId.localeCompare(b.merchantId),
    },
    {
      title: 'Board Name',
      dataIndex: 'boardName',
      key: 'boardName',
      sorter: (a: TrelloSetting, b: TrelloSetting) =>
        a.boardName.localeCompare(b.boardName),
    },
    {
      title: 'Board Url',
      dataIndex: 'boardUrl',
      key: 'boardUrl',
      render: (v: number, record: TrelloSetting) => <a href={`${record.boardUrl}`}>{record.boardUrl}</a>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (v: number, record: TrelloSetting) => {
        return record.settings.status;
      },
    },
    {
      title: 'Operation',
      key: 'operation',
      align: 'center',
      width: '12%',
      render: (v: number, record: TrelloSetting) => {
        return (
          <ButtonGroup>
            <Button shape="circle" icon={<EditOutlined />}
              onClick={() => onEdit(record)}
            />
          </ButtonGroup>
        );

      }
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: '12%',
      fixed: 'right',
      render: (v: number, record: TrelloSetting) => {
        return (
          <Popconfirm title={`Sure to delete ${record.merchantId}?`} onConfirm={() => remove(record.merchantId)}>
            <Button icon={<DeleteOutlined />} danger >delete</Button>
          </Popconfirm>
        );

      }
    }
  ];

  return columns
}
type S =
  | { status: 'loading', openCreate: boolean, deleteLoading: boolean, openUpdate: boolean, selected: TrelloSetting | null }
  | {
    status: 'loaded';
    data: TrelloSetting[];
    openUpdate: boolean;
    openCreate: boolean;
    selected: TrelloSetting | null;
    deleteLoading: boolean;
    searchText: '',
  };
interface P extends RouteComponentProps {
  goToShop: (id: string) => any;
  refresh: () => any;
}
class TelloList extends React.Component<P, S> {
  sub: Subscription;
  constructor(props: P) {
    super(props);
    this.sub = new Subscription();
    this.state = { status: 'loading', openCreate: false, deleteLoading: false, openUpdate: false, selected: null };
    this.onUpdate = this.onUpdate.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.toogleAction = this.toogleAction.bind(this);
    this.remove = this.remove.bind(this);
  }
  componentDidMount() {
    const func = firebase.functions().httpsCallable('trelloByAction');
    this.sub = from(func(
      {
        actionType: 'GetAll',
        merchantId: 'piticafe',
        other: {}
      }
    )).subscribe(
      (result) => {
        const { success, data } = result.data as { success: boolean, data?: any, code?: string }
        if (success) {
          this.setState({
            ...this.state,
            status: 'loaded',
            data: data,
          });
        }

      }
    );
  }
  componentWillUnmount() {
    this.sub.unsubscribe();
  }
  onUpdate(d: TrelloSetting) {
    this.setState({ ...this.state, selected: d, openUpdate: true, openCreate: false });
  }
  remove(merchantId: string) {
    const func = firebase.functions().httpsCallable('trelloByAction');
    this.setState({ ...this.state, deleteLoading: true })
    this.sub = from(func(
      {
        actionType: 'delete',
        merchantId,
        other: {}
      }
    )).subscribe(
      (result) => {
        const { success } = result.data as { success: boolean, data?: any, code?: string }
        if (success) {
          this.props.refresh();
        }
        this.setState({ ...this.state, deleteLoading: false })
      }
    );

  }
  onSearch = (value: any) => {
    if (this.state.status === 'loaded') {
      this.setState({ ...this.state, searchText: value, })
    }
  }
  toogleAction = (toogleType: 'create' | 'update', open?: boolean) => (e: Event) => {
    e.preventDefault();
    if (toogleType === 'create') {
      this.setState({ ...this.state, openCreate: open || !this.state.openCreate, openUpdate: false })
    }
    if (toogleType === 'update') {
      this.setState({ ...this.state, openCreate: false, openUpdate: true })
    }
  }
  render() {
    const data = this.state.status === 'loaded' ? this.state.data : [];
    const searchText = this.state.status === 'loaded' ? this.state.searchText : null;
    const selected = this.state.selected;
    const listForm = (
      <Card
        title="Trello"
        loading={this.state.status !== 'loaded'}
        extra={
          <>
            <ShowBoardList />
            <ShowBoardIdList />
            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={this.toogleAction('create')}
              style={{ marginLeft: 12 }}
            >
              Add Integration
            </Button>
          </>
        }
      >
        {this.state.openCreate && <Card
          title="Add Integration"
          type="inner"
        >
          <AddIntegration cb={(v: Return) => {
            if (v.success) {
              this.props.refresh();
            }
          }} />
        </Card>}
        {(this.state.openUpdate && (this.state.selected !== null)) && <Card
          title="Update Integration"
          type="inner"
        >
          <UpdateIntegration cb={(v: Return) => {
            if (v.success) {
              this.props.refresh();
            }
          }} data={selected || {} as TrelloSetting} />
        </Card>}
        <Card title="Merchant List"
          loading={this.state.status === 'loading'}
          extra={
            <>
              <Search
                placeholder="search merchantId..."
                allowClear
                enterButton="Search"
                size="default"
                onSearch={this.onSearch}
              />
            </>
          }
        >
          <Table
            columns={createColummns(this.onUpdate, this.remove, searchText)}
            dataSource={data}
            pagination={{
              pageSize: 35,
            }}
          />
        </Card>

      </Card>

    );
    const spinerForm = (
      <Spin tip="Loading...">{listForm}</Spin>
    )
    return (this.state.deleteLoading ? spinerForm : listForm);
  }
}
const mapDispatchToProps = (dispatch: any, { history }: P) => {
  return {
    refresh: () => window.location.reload(),
    goToShop: (id: string) => history.push(`/shopDetail/${id}`),
  }
}
export default connect(mapDispatchToProps)(withRouter(TelloList));

