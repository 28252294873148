export interface Action<T> {
  type: string;
  payload: T;
}

export interface AppState {
  authState: AuthState;
}
export interface UserInfo {
  dataPortalEnable: boolean,
  merchantId: string;
  username: string;
  password: string;
  role?: string;
}
export type AuthState =
  | {
    status: 'none' | 'loading';
  }
  | {
    status: 'loggedIn';
    user: firebase.User;
  }
  | {
    status: 'error';
    error: string;
  };

export interface Member {
  memberId: string;
  displayName: string;
  memberPoint: number;
  joinedAt: number;
}
export interface POSSystem {
  itemLimitationCount?: number;
  userCount: number;
  plan: 'Business' | 'Starter';
}
export interface Themes {
  main: string;
  light: string;
  dark: string;
}
export interface ThemeType {
  primary: Themes;
  secondary?: Themes;
}
export interface Palette {
  palette: ThemeType;
}
export interface Currency {
  exchangeRate: number;
  template: string;
  currencySymbol: string;
  currencyCode: string;
}
export interface EnterpriseSetting {
  onlineStore: string;
}
export interface Store {
  id: string;
  status: 'active' | 'inactive';
  address: string;
}

export interface Customer {
  id: string;
  merchantId: string;
  name: string;
  gender: string;
  email: string;
  phone: string;
  birthday: string;
  dateOfBirth: string;
  createdOn: number;
  photo: string;
  address: string;
  company: string;
  groupIds: string;
  updatedAt: Number;
  memberId: string;
}
interface MerchantBase {
  businessName: string;
  phone: string;
  photo?: string;
  fbId: string;
  website: string;
  address: string;
}
export interface RoyalMerchant extends MerchantBase {
  id: string;
  about: string;
  contactName: string;
  email: string;
  loginUserName?: string;
  password?: string;
  memberCount: number;
  balance: number;
  legacy: boolean;
  disabled: boolean;
  dataPortalEnable?: boolean;
  enableSubscription: boolean;
  posEnabled?: boolean;
  posPointSystem?: 'whole' | 'separate';
}
export interface BankAcc {
  name: string;
  number: number;
}
export interface SocialAcc {
  name: string;
  id: string;
}
export interface MenuOption {
  type: string;
  status: 'active' | 'inactive'
}
export interface Merchant {
  id: string;
  posEnabled?: boolean;
  posBusinessIntelligentEnable?: boolean
  posBusinessIntelligentUrl?: string
  posPointSystemEnabled?: boolean
  photo?: string;
  logo?: string;
  favicon?: string;
  posSystem?: POSSystem;
  posShippingSystemEnabled?: boolean;
  theme?: Palette;
  currency?: Currency;
  bankAccount?: any;
  bankAccounts?: BankAcc[];
  socailAccounts?: SocialAcc[];
  menuOptions?: MenuOption[];
  paymentOptions?: MenuOption[];
  stores?: Store[];
  termAndConditions?: string;
  rewardIntegrationEnabled?: boolean;
  thankyouText?: string;
  dataPortalEnable?: boolean;
  enableOffer?: boolean;
  balance?: number;
  enableMemberTier?: boolean;
  paymentAndShippingInfo?: string;
  enterpriseSetting?: EnterpriseSetting;
  merchantType?: 'default' | 'hotel' | 'restaurant'
}
export interface Shop extends RoyalMerchant {
  merchantId: string;
  totalCount: number,
  webCount: number,
  posCount: number,
  lastActive: number;
}
export interface Return {
  success: boolean;
  message?: string;
  data?: any
}
export interface SMS {
  Cancelled: string;
  Completed: string;
  Pending: string;
}
export interface ShippingFee {
  amount: number;
  location: string;
}
export interface SmsMessageCode {
  name: string;
  code: string;
  description: string;
}


export interface UrlToMerchant {
  hostId: string;
  merchantId: string;
}

export interface ShopSummary {
  merchantId: string;
  totalCount: number;
  businessName: string;
  businessAddress: string;
  businessPhoto: string;
  businessPhone: string;
  lastActive: number;
  pendingCount: number;
  completedCount: number;
  cancelledCount: number;
  pendingAmount: number;
  completedAmount: number;
  cancelledAmount: number;
  webPendingCount: number;
  webCompletedCount: number;
  webCancelledCount: number;
  posCompletedCount: number;
}
export const defaultShopSummary: ShopSummary = {
  merchantId: '',
  totalCount: 0,
  businessName: '',
  businessAddress: '',
  businessPhoto: '',
  businessPhone: '09',
  lastActive: 0,
  pendingCount: 0,
  completedCount: 0,
  cancelledCount: 0,
  pendingAmount: 0,
  completedAmount: 0,
  cancelledAmount: 0,
  webPendingCount: 0,
  webCompletedCount: 0,
  webCancelledCount: 0,
  posCompletedCount: 0,
}

export interface Order {
  createdOn: number;
  currency: string;
  customerId?: string
  customerName?: string
  discountIsPercentage: number
  discountName: string
  discountValue: string
  earnPoints: string
  id: string
  merchantId: string
  notes?: string
  orderDate: string
  orderId: string
  orderStatus: string
  orderType: "Web" | "POS"
  paymentType: string
  taxIds: string,
  itemIds?: string,
  itemVariationIds?: string;
  totalAddedTaxAmount: number
  totalAmount: number
  totalDiscountAmount: number
  totalInclusiveTaxAmount: number
  totalNetAmount: number
  updatedAt: number
  userDisplayName: string
  userId: string,
  date?: string;
}

export interface TrelloBoard {
  name: string;
  id: string;
  url: string;
}

export interface TrelloIdList {
  id: string;
  name: string;
}

export interface TrelloIdList {
  id: string;
  name: string;
  key?: string;
}
export interface TrelloSystem {
  status: 'active' | 'inactive'
}
export interface TrelloSetting {
  merchantId: string;
  boardId: string;
  boardName: string;
  boardUrl: string;
  idList: TrelloIdList[];
  totalCards: number;
  settings: TrelloSystem;
}


export interface Item {
  id: string;
  name: string;
  photo?: string;
  category?: ItemCategory;
  modifierIds?: string[];
  optionIds?: string[];
  optionSubIds?: string[];
  applicableTaxIds?: string[];
  description?: string;
  defaultVariation: ItemVariation;
  itemVariationIds: string[];
  pointEnabled?: boolean;
  defaultPoints?: number;
  estatus?: "complete" | "incomplete"
  estock?: StockItems[];
  esetup?: EcommerceItemSetup[];
}
export interface ItemCategory {
  id: string;
  name: string;
  photo?: string;
}
export interface EcommerceItemSetup {
  variationName: string;
  options: VariationOption[];
}
export interface VariationOption {
  name: string;
  id: string;
  stockVarIds?: string[];
  connectVarIds?: string[];
}
export interface StockItems {
  id: string;
  connectorId: string;
  name: string;
}
export interface ItemVariation {
  id: string;
  name: string;
  fromOption: boolean;
  unit: ItemUnit;
  price: number;
  currency: string;
  priceVariable: boolean;
  sku?: string;
  enableInventoryControl: boolean;
  stockQty: number;
  points?: number;
  photo?: string;
  keyIndex?: number;
}
export interface ItemUnit {
  id: string;
  name: string;
  abbr: string;
  precision: string;
}