import firebase from 'firebase/app';
import { Card } from 'antd';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Form, Input, Button, Radio, InputNumber } from 'antd';
import { getMerchant } from "../api";

const { Search } = Input;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
const WebPortal = () => {
    const alert = useAlert()
    const [loading, setLoading] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false);
    const [enableDataPortal, setDataPortal] = useState(false);
    const [merchantId, setMerchantId] = useState(null as string | null);
    const [enableMemberTier, setMemberTier] = useState(false);
    const [enableOffer, setOffer] = useState(false);
    const [boostBalance, setBoostBalance] = useState(0);
    const submitHandler = async () => {
        if (typeof merchantId !== 'string') {
            alert.info('Invalid Merchant ID.')
            return
        }
        setLoading(true);
        let other: any = {};
        other.enableMemberTier = enableMemberTier;
        other.enableOffer = enableOffer;
        other.balance = boostBalance;
        const updateMerchant = firebase.functions().httpsCallable('updateMechantOptions');
        const result = await updateMerchant({
            merchantId: merchantId,
            field: 'dataPortalEnable',
            value: enableDataPortal,
            other
        });
        const authResult = result.data;
        if (authResult.success) {
            alert.success(`Update  Control ${merchantId} success.`);
            setMerchantId(null);
        } else {
            alert.error(`Update  webPortal ${merchantId} failed. [${authResult.message}]`)
        }
        setLoading(false)
    }
    const onSearch = async (merchantId: string) => {
        if (!(typeof merchantId === 'string') || merchantId.length === 0) {
            // alert.info('Invalid Merchant ID.')
            return
        }
        setSearchLoading(true);
        const r = await getMerchant(merchantId)
        if (r.success && r.merchant) {
            const m = r.merchant;
            setMerchantId(merchantId);
            setDataPortal(m.dataPortalEnable ? m.dataPortalEnable : false);
            setMemberTier(m.enableMemberTier ? m.enableMemberTier : false);
            setOffer(m.enableOffer ? m.enableOffer : false);
            setBoostBalance(m.balance || 0);
        }
        setSearchLoading(false);
    }

    return (
        <Form
            {...layout}
        >
            <Form.Item
                {...tailLayout}
            >
                <Search
                    placeholder="enter search merchantId "
                    allowClear
                    //enterButton="Search"
                    size="large"
                    style={{ width: '50%' }}
                    onSearch={onSearch}
                    loading={searchLoading}
                />
            </Form.Item>
            <Form.Item
                label='MerchantId'
                rules={[{ required: true }]}
            >
                <Input
                    placeholder={merchantId}
                    // onChange={onMerchantChange}
                    style={{ width: '30%' }}
                    disabled
                />
            </Form.Item>
            <Form.Item
                label="Enable DataPortal"
            >
                <Radio.Group name="radiogroup"
                    value={enableDataPortal}
                    onChange={(e: any) => setDataPortal(e.target.value)}
                >
                    <Radio value={true} >Enable</Radio>
                    <Radio value={false} >Disable</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="Enable MemberTier"
            >
                <Radio.Group name="radiogroup"
                    value={enableMemberTier}
                    onChange={(e: any) => setMemberTier(e.target.value)}
                >
                    <Radio value={true} >Enable</Radio>
                    <Radio value={false} >Disable</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="Enable Offer"
            >
                <Radio.Group name="radiogroup"
                    value={enableOffer}
                    onChange={(e: any) => setOffer(e.target.value)}
                >
                    <Radio value={true} >Enable</Radio>
                    <Radio value={false} >Disable</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="Boost Balance"
            >
                <InputNumber min={0} max={100000} value={boostBalance} onChange={(value: any) => setBoostBalance(parseInt(value))} />
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={submitHandler}
                    loading={loading}
                    disabled={searchLoading || !merchantId}
                >
                    Save
                </Button>
            </Form.Item>
        </Form>
    );
};

class Toolkit extends React.Component<{}, {}> {
    constructor(props: {}) {
        super(props)
        this.state = {
            status: 'loading'
        }
    }
    render() {
        // const data = this.state.status === 'loaded' ? this.state.data : [];
        return (
            <Card title="Controls" style={{ height: "100%" }}>
                <WebPortal />
            </Card>
        );
    }
}

export default Toolkit;
