import { Avatar, Divider, Dropdown, Icon, Layout, Menu } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { actions } from '../auth';
import { AppState } from '../store';

const mapStateToProps = ({ authState }: AppState) => {
  if (authState.status === 'loggedIn') {
    const { uid, displayName } = authState.user;
    return {
      uid,
      name: displayName || '',
    };
  }
  return {
    uid: '',
    name: '',
  };
};
const mapDispatchToProps = (dispatch: Dispatch, { history }: Props) => {
  const { loggedOut } = actions;

  return {
    onMenuClick: (item: { key: string }, id?: string) => {
      switch (item.key) {
        case 'logout': {
          history.push('/');
          dispatch(loggedOut());
          break;
        }
        default: {
          history.push(`/${item.key}`);
          break;
        }
      }
    },
  };
};
interface Props extends RouteComponentProps {
  uid: string;
  name: string;
  onMenuClick: (item: { key: string }, id?: string) => void;
}
class TopBar extends React.Component<Props> {
  handleMenuClick = (item: { key: string }) => {
    const { onMenuClick, uid } = this.props;
    onMenuClick(item, uid);
  };

  render() {
    const { name } = this.props;

    const menu = (
      <Menu className="menu" selectedKeys={[]} onClick={this.handleMenuClick}>
        <Menu.Item key="logout">
          <Icon type="logout" />
          Log out
        </Menu.Item>
      </Menu>
    );
    return (
      <Layout className="top-bar">
        <div className="container">
          <div className="right-half">
            <div style={{ float: 'right' }}>
              <Divider type="vertical" />
              <Dropdown overlay={menu}>
                <span className="action account">
                  <Avatar
                    className="avator"
                    size="small"
                    icon="user"
                    src="/profile.png"
                  />
                  {name}
                </span>
              </Dropdown>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopBar)) as any

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopBar)
) as any;