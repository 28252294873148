import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ShopSummary } from "../state";
import moment from 'moment';
const options = (shops: ShopSummary[]) => {
    const oneMonth = moment().subtract(1, 'months').unix() * 1000
    const inactive = shops.filter(s => s.lastActive < oneMonth).length
    const active = shops.filter(s => s.lastActive > oneMonth).length
    const options: Highcharts.Options = {
        title: {
            text: 'Shops Activity'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size: '100%',
                dataLabels: {
                    enabled: false,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                },
                showInLegend: true,
            }
        },
        series:
            [
                {
                    type: 'pie',
                    colorByPoint: true,
                    data: [{
                        name: 'Active',
                        y: active,
                        color: 'green',
                        sliced: true,
                        selected: true
                    }, {
                        name: 'Inactive',
                        color: '#c91844',
                        y: inactive
                    },]
                }
            ]
    }
    return options
}

interface Props extends HighchartsReact.Props {
    data: ShopSummary[];
}
const App = (props: Props) => <div>
    <HighchartsReact
        highcharts={Highcharts}
        options={options(props.data)}
        {...props}
    />
</div>

export default App;