import firebase from 'firebase/app';
import { Action } from '../store';

export const actionTypes = {
  LOGIN_REQUIRE: 'auth/LOGIN_REQUIRE',
  LOGIN_REQUEST: 'auth/LOGIN_REQUEST',
  LOGIN_REQUEST_FAILED: 'auth/LOGIN_REQUEST_FAILED',
  LOGOUT_REQUEST: 'auth/LOGOUT_REQUEST',
  LOGGED_OUT: 'auth/LOGGED_OUT',
  LOGGED_IN: 'auth/LOGGED_IN',
};

export interface LoginRequestPayload {
  username: string;
  password: string;
  nextRoute: string;
}

const {
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILED,
  LOGGED_IN,
  LOGOUT_REQUEST,
  LOGGED_OUT,
} = actionTypes;

export function loginRequest(username: string, password: string) {
  return {
    type: LOGIN_REQUEST,
    payload: { username, password },
  } as Action<LoginRequestPayload>;
}

export function loggedIn(user: firebase.User) {
  return {
    type: LOGGED_IN,
    payload: { user },
  };
}

export function loginFailed(error: string) {
  return {
    type: LOGIN_REQUEST_FAILED,
    payload: { error },
  };
}

export function logOutRequest() {
  return {
    type: LOGOUT_REQUEST,
    payload: {},
  };
}

export function loggedOut() {
  return {
    type: LOGGED_OUT,
    payload: {},
  };
}
