import 'antd/lib/form/style';
import 'antd/lib/input/style';
import * as React from 'react';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router';
import './App.less';
import { Login } from './auth';
import './firebase';
import history from './history';
import Root from './Root';
import { store } from './store';
class App extends React.Component<{}> {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/" component={Root} />
        </Switch>
      </Router>
    );
  }
}

export default class extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    );
  }
}
