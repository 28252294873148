import { Card, Row, Col, Select } from 'antd';
import firebase from 'firebase/app';
import React from 'react';
import Barchart from './chart/shop_by_order_vertical_chart';
import { ShopFilled, CopyFilled, GlobalOutlined, ShoppingFilled } from "@ant-design/icons";
import { from, Subscription } from 'rxjs';
import PanelCard from "./components/panel_card";
import LastActiveOrder from './last_active_order_list';
import InactiveShop from './inactive_shop_list';
import ShopDetail from './shop_detail_dashboard';
import ActiveInactiveChart from './chart/shop_activity_chart';
import * as _ from 'lodash';
import { UrlToMerchant, ShopSummary, defaultShopSummary } from "./state";
const { Option } = Select

type S =
    | { status: 'loading' }
    | {
        status: 'loaded';
        data: { shopList: UrlToMerchant[], shopData: ShopSummary[], currentShopId: string };
    };
class SummaryDashboard extends React.Component<{}, S> {
    sub: Subscription;
    constructor(props: {}) {
        super(props);
        this.sub = new Subscription();
        this.state = { status: 'loading', };
        this.onChange = this.onChange.bind(this)
    }
    componentDidMount() {
        const getAllMembers = firebase.functions().httpsCallable('mcommerceSummaryDashboard');
        this.sub = from(getAllMembers({})).subscribe(
            (result) => {
                let { status, data } = result.data as { status: 'success' | 'invalid', data?: { shopList: UrlToMerchant[], shopData: ShopSummary[] } };
                if (status === 'success') {
                    let _list: UrlToMerchant[] = [], _data: ShopSummary[] = [];
                    // let  filterList: UrlToMerchant[] = [];
                    if (data) {
                        _list = data.shopList
                        _data = data.shopData
                    }
                    // //console.log('before ', list.length)
                    // let filters: ShopSummary[] = []
                    // _list.forEach(s => {
                    //     const index = _data.findIndex(d => d.merchantId === s.merchantId)
                    //     if (index > -1 && (filters.findIndex(a => a.merchantId === s.merchantId) < 0)) {
                    //         filters.push(_data[index])
                    //     }
                    //     if (index > -1 && filterList.findIndex(l => l.merchantId === s.merchantId) < 0) {
                    //         filterList.push(s)
                    //     }
                    // })
                    this.setState({
                        status: 'loaded',
                        data: { shopList: _list, shopData: _data, currentShopId: 'piticafe' },
                    });
                }

            }
        );
    }
    componentWillUnmount() {
        this.sub.unsubscribe();
    }
    onChange(value: string) {
        if (this.state.status === 'loaded') {
            const { shopList, shopData } = this.state.data
            this.setState({ status: 'loaded', data: { shopList, shopData, currentShopId: value } })
        }

    }
    getTotalShops(list: ShopSummary[]) {
        return list.length
    }
    getTotalOrder(data: ShopSummary[]) {
        const web = data.reduce((a, { webCompletedCount }) => a + webCompletedCount, 0)
        const pos = data.reduce((a, { posCompletedCount }) => a + posCompletedCount, 0)
        return web + pos
    }
    getWebOrder(data: ShopSummary[]) {
        return data.reduce((a, { webCompletedCount }) => a + webCompletedCount, 0)
    }
    getPOSOrder(data: ShopSummary[]) {
        return data.reduce((a, { posCompletedCount }) => a + posCompletedCount, 0)
    }
    getTop10Shop(data: ShopSummary[]) {
        return _.orderBy(data, 'completedCount', 'desc')
    }
    getLastActiveShops(data: ShopSummary[]) {
        const arr = _.orderBy(data, 'lastActive', 'desc')
        return arr.length > 7 ? arr.splice(0, 6) : arr
    }
    getCurrentShop(merchantId: string, data: ShopSummary[]) {
        const index = data.findIndex(s => s.merchantId === merchantId)
        return index > -1 ? data[index] : defaultShopSummary
    }
    render() {
        //const list = this.state.status === 'loaded' ? this.state.data.shopList : [];
        const data = this.state.status === 'loaded' ? this.state.data.shopData : [];
        const merchantId = this.state.status === 'loaded' ? this.state.data.currentShopId : '';
        const loading = this.state.status !== 'loaded';
        return (
            <>
                <Row gutter={[48, 16]}>
                    <Col span={6} >
                        <PanelCard loading={loading} icon={(<ShopFilled style={{ fontSize: 60 }} />)} title='Shops' primary={this.getTotalShops(data)} />
                    </Col>
                    <Col span={6} >
                        <PanelCard loading={loading} icon={(<CopyFilled style={{ fontSize: 60 }} />)} title='Orders' primary={this.getTotalOrder(data)} />
                    </Col>
                    <Col span={6} >
                        <PanelCard loading={loading} icon={(<GlobalOutlined style={{ fontSize: 60 }} />)} title='Web Orders' primary={this.getWebOrder(data)} />
                    </Col>
                    <Col span={6} >
                        <PanelCard loading={loading} icon={(<ShoppingFilled style={{ fontSize: 60 }} />)} title='POS Orders' primary={this.getPOSOrder(data)} />
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }} gutter={24}>
                    <Col span={24}>
                        <Card loading={loading} style={{ boxShow: 'none', border: 'none' }}>
                            <Row gutter={24}>
                                <Col span={14}><Barchart data={this.getTop10Shop(data)} /></Col>
                                <Col span={10}><LastActiveOrder data={this.getLastActiveShops(data)} /></Col>
                            </Row>
                        </Card>
                    </Col>

                </Row>
                <Row style={{ marginTop: 10 }} gutter={24}>
                    <Col span={24}>
                        <Card
                            loading={loading}
                            title='Shop Summary' extra={
                                <span>
                                    Select Shop
                                <Select
                                        showSearch
                                        defaultValue={merchantId}
                                        style={{ width: 200, marginLeft: 5 }}
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        onChange={this.onChange}
                                    >
                                        {
                                            data.map(s => (<Option key={s.merchantId} value={s.merchantId.toString()} >{s.merchantId.toString()} </Option>))
                                        }
                                    </Select>
                                </span>
                            }>
                            <ShopDetail data={this.getCurrentShop(merchantId, data)} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }} gutter={24} >
                    <Col span={24}>
                        <Card loading={loading} title='Shops Activity'>
                            <Row gutter={24}>
                                <Col span={8}>

                                    <ActiveInactiveChart data={data} />

                                </Col>
                                <Col span={16}>

                                    <InactiveShop data={data} />

                                </Col>

                            </Row>
                        </Card>
                    </Col>
                </Row>

            </>
        );
    }
}

export default SummaryDashboard;
