import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, List, Input, Form, } from 'antd';
// import firebase from "firebase/app";
import { getTrelloBoards, addTrelloBoard } from "../api";
import { CopyOutlined, PlusOutlined, CodeOutlined } from '@ant-design/icons';
import { TrelloBoard } from "../store";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAlert } from 'react-alert';
const { TextArea } = Input
const originData: TrelloBoard[] = []
const BoardList = () => {
    const alert = useAlert();
    const [form] = Form.useForm();
    const [visible, setVisible] = React.useState(false);
    const [state, setState] = useState({ status: 'loading', data: originData })
    const [copyIndex, setCopyIndex] = useState(-1)
    const [openCreate, setOpenCreate] = useState(false);
    const [loading, setLoading] = useState(false)
    const copied = (i: number) => i === copyIndex;
    useEffect(() => {
        getTrelloBoards().then((result) => {
            if (result.success) {
                const _state = { ...state }
                setState({ ..._state, status: 'loaded', data: result.boards || [] })
            }
        }).catch(error => {
            console.log(error)
        })
    }, [])
    const showModal = () => {
        setVisible(true);
    };
    const handleOk = async () => {
        setVisible(false)

    };
    const handleCancel = () => {
        setVisible(false);
    };
    const fetchBoards = () => {
        getTrelloBoards().then((result) => {
            if (result.success) {
                const _state = { ...state }
                setState({ ..._state, status: 'loaded', data: result.boards || [] })
            }
        }).catch(error => {
            console.log(error)
        })
    }
    const onFinish = async (values: any) => {
        setLoading(true)
        setState({ ...state, status: 'loading' })
        const { name, desc } = values
        addTrelloBoard(name, desc, (success, err, data) => {
            setLoading(false);
            if (success) {
                form.resetFields();
                alert.success("Success: Added Board");
                fetchBoards();
            } else {
                alert.error(err)
                setState({ ...state, status: 'loaded' })
            }
        });
    }
    return (
        <>
            <Button icon={<CodeOutlined />} onClick={showModal}>
                show boards
            </Button>
            <Modal
                title="Boards"
                visible={visible}
                onOk={handleOk}
                confirmLoading={false}
                onCancel={handleCancel}
            >
                <Card loading={state.status !== 'loaded'}
                    extra={
                        <>
                            {/* <MessageCode /> */}
                            <Button
                                type='primary'
                                icon={<PlusOutlined />}
                                onClick={() => setOpenCreate(!openCreate)}
                                style={{ marginLeft: 12 }}
                            >
                                Add Board
                            </Button>
                        </>}
                >
                    {
                        openCreate && <Card title="Create Board" type="inner">
                            <Form form={form} name="horizontal_board" onFinish={onFinish}>
                                <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: 'Please input your board name!' }]}
                                    style={{ marginTop: 10 }}
                                >
                                    <Input
                                        addonBefore='name'
                                        placeholder="enter board name...."
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="desc"
                                    rules={[{ required: true, message: 'Please input your board desc.' }]}
                                    style={{ marginTop: 10 }}
                                >
                                    <TextArea addonBefore='board desc' placeholder="your board description" />
                                </Form.Item>
                                <Form.Item shouldUpdate style={{ marginTop: 10 }}>
                                    {() => (
                                        loading ? <Button type='primary' loading>loading</Button> :
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={
                                                    !form.isFieldsTouched(true) ||
                                                    !!form.getFieldsError().filter((v: any) => v.errors.length).length
                                                }
                                            >
                                                create
                                            </Button>
                                    )}
                                </Form.Item>
                            </Form>

                        </Card>
                    }

                    <List
                        itemLayout="horizontal"
                        dataSource={state.data}
                        bordered={false}
                        split={false}
                        renderItem={(item: TrelloBoard, index: number) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Input value={item.name} disabled={true} />}
                                    title={<Input value={item.id} disabled={true}
                                        addonAfter={
                                            <CopyToClipboard
                                                text={item.id}
                                                onCopy={() => setCopyIndex(index)}
                                            >
                                                <span>
                                                    {
                                                        copied(index) ? <a style={{ color: 'green' }}>Copied!</a> : <CopyOutlined />
                                                    }
                                                </span>
                                            </CopyToClipboard>

                                        } />}
                                />
                                <br /><a href={item.url}>Go To Board</a>
                            </List.Item>
                        )} />
                </Card>
            </Modal>
        </>
    );
};
export default BoardList;
