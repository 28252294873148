import { Card, Table, Avatar, Button, Input } from 'antd';
import * as _ from 'lodash';
import React from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { from, Subscription } from 'rxjs';
import { Customer } from '../store';
import { getCustomers } from "../api";
import ButtonGroup from 'antd/lib/button/button-group';
import nophoto from './noprofile.png';
export const createRows = (rows: Customer[] = []) => {
    let i = 0
    return rows.length > 0 ?
        rows.map(row => {
            i++;
            return {
                key: i,
                id: row.id,
                name: row.name,
                email: row.email,
                phoneNo: row.phone,
                photo: row.photo,
                dateOfBirth: row.dateOfBirth,
                gender: row.gender,
                address: row.address,
                date: new Date(row.createdOn).toDateString(),
            }
        }) :
        []
}


export const createColumns = (searchText: any) => {
    let isPhNo = false;
    if (searchText && (typeof searchText === 'string')) {
        if (searchText.startsWith('09') || searchText.startsWith('+95')) {
            isPhNo = true
            searchText = searchText.startsWith('09') ? '+95 ' + searchText.substring(1) : searchText
        }
    }
    const columns = [
        {
            title: 'Photo',
            width: 100,
            key: 'photo',
            // fixed: 'left',
            render: (v: { photo: string }) => < Avatar src={v.photo || nophoto} size={70} />

        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            // align: 'left',
            width: 120,
            filteredValue: (!isPhNo && searchText) ? [searchText] : null,
            onFilter: (value: string, record: any) => record.name.toLocaleLowerCase().includes(searchText),
            ellipsis: true,
            render: (text: any) => <b style={{ color: 'blue' }}>{text}</b>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 150,
            render: (text: any) => text
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNo',
            key: 'phoneNo',
            width: 150,
            filteredValue: (isPhNo && searchText) ? [searchText] : null,
            onFilter: (value: string, record: any) => record.phoneNo.includes(value),
            ellipsis: true,
            render: (text: any) => text
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            width: 120,
            render: (text: any) => text
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: 120,
            render: (text: any) => text
        },
        {
            title: 'DateOfBirth',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            width: 120,
            render: (text: any) => text
        },
        {
            title: 'Action',
            key: 'action',
            //align: 'center',
            width: '12%',
            // fixed: 'right',
            render: (v: { id: string }) => {
                return (
                    <ButtonGroup>
                        <Button shape="circle" icon={<EyeOutlined />}
                            onClick={() => { }}
                        />
                    </ButtonGroup>
                );


            }
        }

    ];
    return columns
}

type S =
    | { status: 'loading' }
    | {
        status: 'loaded';
        data: Customer[];
        searchText: '';
    };
interface P {
    merchantId: string;
}
class CustomerList extends React.Component<P, S> {
    sub: Subscription;
    constructor(props: P) {
        super(props);
        this.sub = new Subscription();
        this.state = { status: 'loading' };
        this.onSearch = this.onSearch.bind(this)
    }
    componentDidMount() {
        const { merchantId } = this.props
        this.sub = from(getCustomers(merchantId)).subscribe(
            (result) => {
                if (result.success) {
                    this.setState({
                        status: 'loaded',
                        data: result.customers,
                    });
                }

            }
        );
    }
    componentWillUnmount() {
        this.sub.unsubscribe();
    }
    onSearch(search: any) {
        if (this.state.status === 'loaded') {
            this.setState({ ...this.state, searchText: search })
        }
    }
    render() {
        const data = this.state.status === 'loaded' ? this.state.data : [];
        const searchText = this.state.status === 'loaded' ? this.state.searchText : null
        return (
            <Card title="Customers"
                style={{ border: 'none' }}
                loading={this.state.status === 'loading'}
                extra={
                    <>
                        <Input
                            placeholder="search name Or phone..."
                            size="default"
                            onChange={(e: any) => this.onSearch(e.target.value)}
                        />
                    </>
                }

            >
                <Table
                    columns={createColumns(searchText)}
                    dataSource={createRows(data)}
                    pagination={{
                        pageSize: 10,
                    }}
                />
            </Card>
        );
    }
}
export default CustomerList;

