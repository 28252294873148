import React from 'react';
import { Table, Row, Col, Avatar } from 'antd';
import { ShopSummary } from "./state";
import * as _ from 'lodash';
import moment from 'moment';

const columns = [
    {
        title: 'Photo',
        width: 100,
        key: 'photo',
        fixed: 'left',
        render: (v: { photo: string }) => < Avatar src={v.photo} size={70} />

    },
    {
        title: 'Shop',
        dataIndex: 'merchantId',
    },
    {
        title: 'TotalOrder',
        dataIndex: 'orders',
    },
    {
        title: 'Last Order',
        dataIndex: 'lastactive',
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
    },
    // {
    //     width: 200,
    //     title: 'Address',
    //     dataIndex: 'address'
    // }
];

const rows = (data: ShopSummary[]) => {
    // inactive calculate 
    //1622014016
    //1624612292717
    const oneMonth = moment().subtract(1, 'months').unix() * 1000
    data = data.filter(s => s.lastActive < oneMonth)
    data = _.orderBy(data, 'lastActive', 'asc')
    let i = 0
    const rows = data.map(shop => {
        i = i + 1;
        return {
            key: i.toString(),
            photo: shop.businessPhoto,
            merchantId: shop.businessName,
            orders: shop.completedCount,
            lastactive: moment(shop.lastActive).format("DD MMM YYYY hh:mm a"),
            phone: shop.businessPhone,
            address: shop.businessAddress
        }
    })

    return rows
}
interface Props {
    data: ShopSummary[];
}
const App = (props: Props) => {
    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={rows(props.data)}

                        pagination={
                            {
                                pageSize: 5,
                                hideSelectionColumn: true,
                            }
                        }
                        size="small"
                    />
                </Col>
            </Row>
        </>
    )
}
export default App;