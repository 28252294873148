import React, { useState, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { Return, SMS } from "../tools/state";
import firebase from 'firebase/app';
const { TextArea } = Input
interface Props {
    cb: (v: Return) => void,
}
const HorizontalSMSForm = (props: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [, forceUpdate] = useState({});
    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate({});
    }, []);

    const onFinish = async (values: any) => {
        setLoading(true)
        const { cancel, confirm, pending, merchantId } = values
        const hostfunc = firebase.functions().httpsCallable('smsByAction');
        const sms: SMS = { Cancelled: cancel, Completed: confirm, Pending: pending }
        const result = await hostfunc({
            actionType: 'create',
            merchantId,
            data: sms
        });
        const { success, code } = result.data as { success: boolean, data?: any, code?: string }
        setLoading(false)
        props.cb({ success: success, data: { ...values }, message: code })
    };

    return (
        <Form form={form} name="horizontal_sms" layout="inline" onFinish={onFinish}>
            <Form.Item
                name="merchantId"
                rules={[{ required: true, message: 'Please input your merchantId!' }]}
                style={{ marginTop: 10 }}
            >
                <Input
                    addonBefore='merchantId'
                    placeholder="Enter merchantId(eg...piticafe)"
                />
            </Form.Item>
            <Form.Item
                name="pending"
                rules={[{ required: true, message: 'Please input your order pending text!' }]}
                style={{ marginTop: 10 }}
            >
                <TextArea addonBefore='Pending sms' placeholder="your order is pending" />
            </Form.Item>
            <Form.Item
                name="confirm"
                rules={[{ required: true, message: 'Please input your order confirm text!' }]}
                style={{ marginTop: 10 }}
            >
                <TextArea addonBefore='Confirmed sms' placeholder="your order is confirmed" />
            </Form.Item>
            <Form.Item
                name="cancel"
                rules={[{ required: true, message: 'Please input your order cancel text!' }]}
                style={{ marginTop: 10 }}
            >
                <TextArea addonBefore='Cancelled sms' placeholder="your order is cancelled" />
            </Form.Item>
            <Form.Item shouldUpdate style={{ marginTop: 10 }}>
                {() => (
                    loading ? <Button type='primary' loading>loading</Button> :
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                                !form.isFieldsTouched(true) ||
                                !!form.getFieldsError().filter((v: any) => v.errors.length).length
                            }
                        >
                            create
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};

export default HorizontalSMSForm;