import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Alert from "react-alert";
import AlertTemplate from 'react-alert-template-basic'
import * as serviceWorker from './serviceWorker';
import './firebase';
const options = {
    offset: '40px',
    timeout: 3000,
    position: Alert.positions.TOP_CENTER,
    transition: Alert.transitions.SCALE
};
ReactDOM.render(
    <Alert.Provider template={AlertTemplate} {...options}>
        <App />
    </Alert.Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
