import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ShopSummary } from "../state";
const options = (shops: ShopSummary[]) => {
    var data = Array(shops.length).fill(2).map(p => Array(2).fill(2).map(p => p))
    let max = 0
    shops.forEach((s, index) => {
        data[index][0] = s.businessName
        data[index][1] = s.totalCount
        max = s.totalCount > max ? s.totalCount : max
    })
    max += 30
    data = data.sort((a, b) => b[1] - a[1])
    const options: Highcharts.Options = {
        title: {
            text: 'Mcommerce Top Selling Shops'
        },
        // subtitle: {
        //     text: 'Source: <a href="http://en.wikipedia.org/wiki/List_of_cities_proper_by_population">Wikipedia</a>'
        // },
        xAxis: {
            type: 'category',
            labels: {
                rotation: -45,
                style: {
                    fontSize: '13px',
                    fontFamily: 'Verdana, sans-serif'
                }
            },
            min: 0,
            max: 20,
            scrollbar: {
                enabled: true
            }

        },
        yAxis: {
            min: 0,
            max: max,
            title: {
                text: 'Selling (orders)'
            },
        },
        legend: {
            enabled: false
        },
        tooltip: {
            pointFormat: '<b>{point.y:.1f} orders</b>'
        },
        credits: {
            enabled: false
        },
        series: [{
            type: 'bar',
            name: 'Top Selling',
            data: data,
        }]
    };
    return options
}
interface Props extends HighchartsReact.Props {
    data: ShopSummary[];
}
const App = (props: Props) => <div>
    <HighchartsReact
        highcharts={Highcharts}
        options={options(props.data)}
        {...props}
    />
</div>

export default App;