import React, { useState } from 'react';
import { EcommerceItemSetup, Item, VariationOption } from "../../store";
import { Button, List, Card, Form, Input, Typography } from "antd";
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
interface P {
    item: Item,
    cb: (data: any) => any;
}
const { Text } = Typography;
const xPairOfArrary = (p: any, q: any) => {
    let pairs: any = [];
    for (const i of p) {
        q.forEach((k: any) => {
            pairs.push([i, k]);
        });
    }
    return pairs;
}
const initItemSetup: EcommerceItemSetup[] = [];
const initError: string | null = null
export default function VariationFormat(props: P) {
    const [form] = Form.useForm();
    const [items, setItems] = useState(initItemSetup);
    const [showVarForm, setShowVarForm] = useState(false);
    const [error, setError] = useState(initError);
    const onAddOption = (varId: number) => {
        const _items = [...items];
        const options = _items[varId].options;
        options.push({ name: "", id: "" });
        setItems(_items);
    }
    const onChanges = (varId: number, index: number, name: string, value: any) => {
        const _items = [...items];
        const options = _items[varId].options;
        options[index][name] = value.target.value;
        setItems(_items);
    }
    const onRemoveOption = (varId: number, index: number) => {
        let _items = [...items]
        const options = _items[varId].options
        const rm = options.filter((v: any, i: number) => i !== index);
        _items[varId].options = rm;
        if (rm.length === 0) {
            _items = _items.filter((v: any, index: number) => index !== varId);
        }
        setItems(_items);
    }
    const onAddVariation = (values: any) => {
        const { name } = values;
        const finder = items.findIndex(iv => iv.variationName.toLowerCase() === name.toLocaleLowerCase());
        if (finder === -1) {
            items.push({ variationName: name, options: [] });
        }
        setShowVarForm(false);
    }
    const AddVariationForm = () => {
        return (
            <Form form={form} name="horizontal_host" layout="inline" onFinish={onAddVariation}>
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Please input variation name!' }]}
                >
                    <Input addonBefore='name' placeholder="variation name" />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                                !form.isFieldsTouched(true) ||
                                !!form.getFieldsError().filter((v: any) => v.errors.length).length
                            }
                        >
                            Add
                        </Button>
                    )}
                </Form.Item>
            </Form>
        );
    }
    const onSetupSumbit = () => {
        if (items.length === 2) {
            let p: string[] = [];
            let q: string[] = [];
            let index = 0;
            for (const item of items) {
                const filters = item.options.filter(v => v.name !== "").map(vv => `${item.variationName}->${vv.name}`);
                if (filters.length > 0) {
                    if (index === 0) {
                        p = filters;
                    } else {
                        q = filters;
                    }
                }
                index += 1;
            }
            if (p.length > 0 && q.length > 0) {
                const pairs = xPairOfArrary(p, q);
                if (props.cb) props.cb({ items, pairs });
            }
        } else {
            setError("Invalid Format");
        }
    }
    return (
        <div>
            <div>
                {(props.item && props.item.itemVariationIds.length > 0) && <div>
                    <ol>
                        {
                            props.item.itemVariationIds.map(iv => (<li>{iv}</li>))
                        }

                    </ol>
                </div>}
            </div>
            {
                showVarForm &&
                <div>
                    <Card
                        type="inner"
                        title={`Add new variation for (${props.item ? props.item.name : ""} )`}
                    >
                        < AddVariationForm />
                    </Card>
                </div>
            }
            <Card
                type="inner"
                title={`Setup Ecommerce Item(${props.item.name})`}
                extra={
                    <>
                        <Button
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => setShowVarForm(!showVarForm)}
                        >
                            Add Variation
                        </Button>
                    </>
                }
            >
                {items.map((si, varIndex) => {
                    return (
                        <>
                            <h3>{si.variationName}</h3>
                            {(si.options.length > 0) && <List
                                itemLayout="horizontal"
                                dataSource={si.options}
                                bordered={false}
                                split={false}
                                renderItem={(item: VariationOption, index: number) => (
                                    <List.Item
                                        style={{ withd: "80%" }}
                                        extra={
                                            <Button shape="circle" icon={<CloseOutlined />} onClick={() => onRemoveOption(varIndex, index)} />
                                        }
                                    >
                                        <List.Item.Meta
                                            style={{ width: 200 }}
                                            title={<Input placeholder="variation options name" value={item.name} onChange={(e: any) => onChanges(varIndex, index, 'name', e)} />}
                                        />
                                    </List.Item>
                                )} />
                            }
                            <Button icon={<PlusOutlined />} onClick={() => onAddOption(varIndex)}>Add Option({si.variationName})</Button>
                        </>
                    )
                })}
            </Card>
            {error && <Text type="danger">{error}</Text>}
            <br />
            < Button style={{ marginTop: 5 }} onClick={onSetupSumbit} >Setup Items</Button>
        </div>
    )
}
