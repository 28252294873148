import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Spin, List } from 'antd';
import { getTrelloBoards, getBoardIdList } from "../api";
import { useAlert } from 'react-alert';
import { Return } from "../tools/state";
import firebase from 'firebase/app';
import { TrelloBoard, TrelloIdList, TrelloSetting, TrelloSystem } from '../store/state';
const { Option } = Select
interface Props {
    cb: (v: Return) => void,
}
const originData: TrelloBoard[] = []
const idOriginData: TrelloIdList[] = [];
const AddMerchantIntegration = (props: Props) => {
    const alert = useAlert();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [spinLoading, setSpinloading] = useState(false)
    const [currenBoard, setCurrentBoard] = useState({ id: '1', name: 'name', url: '' } as TrelloBoard);
    const [state, setState] = useState({ status: 'loading', boards: originData, idList: idOriginData })
    const [, forceUpdate] = useState({});
    useEffect(() => {
        forceUpdate({});
        getTrelloBoards().then((result) => {
            if (result.success) {
                const _state = { ...state }
                setState({ ..._state, status: 'loaded', boards: result.boards || [] })
            }
        }).catch(error => {
            console.log(error)
        })
    }, []);

    const onFinish = async (values: any) => {
        if (!completedForm()) return;
        setLoading(true)
        const { merchantId } = values
        const other: TrelloSetting = {
            merchantId,
            boardId: currenBoard.id,
            boardName: currenBoard.name,
            boardUrl: currenBoard.url,
            idList: state.idList.filter(iList => iList.key !== undefined),
            totalCards: 0,
            settings: { status: 'active' } as TrelloSystem
        }
        const func = firebase.functions().httpsCallable('trelloByAction');
        const result = await func({
            actionType: 'create',
            merchantId,
            other: { ...other }
        });
        const { success, code } = result.data as { success: boolean, data?: any, code?: string }
        if (success) {
            alert.success("Success Add integeration");
            props.cb({ success: true })
            form.resetFields();
        } else {
            alert.error(`Failed:${code}`);
        }
        setLoading(false)
    };
    const completedForm = () => {
        if (state.idList.length < 1) return false;
        if (currenBoard.id === '1') return false;
        if (!(state.idList.filter(v => v.key !== undefined).length >= 3)) return false;
        return true;
    }
    const onChange = async (boardId: string) => {
        setSpinloading(true)
        getBoardIdList(boardId).then(res => {
            form.setFieldsValue({ boardId: boardId });
            if (res.success) {
                setState({ ...state, idList: res.list || [] });
                setCurrentBoard(state.boards.filter(b => b.id === boardId)[0])
            }
            setSpinloading(false);
        });
    }
    const onBoardIdChange = (IdList: string, key: string) => {
        const index = state.idList.findIndex(v => v.id === IdList);
        if (index > -1) {
            state.idList[index].key = key;
            setState({ ...state })
        }
    }
    const createdForm = (
        <Form form={form} name="horizontal_sms" onFinish={onFinish}>
            <Form.Item
                name="merchantId"
                label="merchantId"
                rules={[{ required: true, message: 'Please input your merchantId!' }]}
                style={{ marginTop: 10 }}
            >
                <Input
                    style={{ width: "50%" }}
                    // addonBefore='merchantId'
                    placeholder="Enter merchantId(eg...piticafe)"
                />
            </Form.Item>
            <Form.Item name="boardId"
                label="Select board"
                rules={[{ required: true, message: 'Please select board!' }]}
            >
                <Select style={{ width: "50%" }}
                    filterOption={(input: any, option: any) => option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={onChange}
                >
                    {
                        state.boards.map(s => (<Option key={s.name} value={s.id} >{s.name} </Option>))
                    }
                </Select>
            </Form.Item>
            {
                state.idList.length > 1 && <Form.Item label="Board IdList">
                    <List
                        itemLayout="horizontal"
                        dataSource={state.idList}
                        renderItem={(item: TrelloIdList) => (
                            <List.Item style={{ width: '50%' }}>
                                <List.Item.Meta
                                    title={item.name}
                                    description={`${item.id} `}
                                />
                                <Select
                                    defaultValue="Unknown"
                                    placeholder="select actions"
                                    style={{ width: 200 }}
                                    onChange={(key: string) => onBoardIdChange(item.id, key)}>
                                    <Option value="Pending">Pending Orders</Option>
                                    <Option value="Completed">Completed Orders</Option>
                                    <Option value="Cancelled" >
                                        Cancelled orders
                                    </Option>
                                    <Option value="Unknown" >
                                        Unknown
                                    </Option>
                                </Select>
                            </List.Item>
                        )}
                    />
                </Form.Item>
            }
            <Form.Item shouldUpdate style={{ marginTop: 10 }}>
                {() => (
                    loading ? <Button type='primary' loading>loading</Button> :
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                                !completedForm() ||
                                !!form.getFieldsError().filter((v: any) => v.errors.length).length
                            }
                        >
                            create
                        </Button>
                )}
            </Form.Item>

        </Form>
    );
    const spinerForm = (
        <Spin tip="Loading...">{createdForm}</Spin>
    );
    return (
        spinLoading ? spinerForm : createdForm
    );
};

export default AddMerchantIntegration;