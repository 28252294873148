import React from 'react';
import { List, Avatar } from 'antd';
import { ShopSummary } from "./state";
import moment from 'moment';
interface Props {
    data: ShopSummary[];
}
const App = (props: Props) => {
    return (
        <List
            itemLayout="horizontal"
            dataSource={props.data}
            renderItem={(item: ShopSummary) => (
                <List.Item>
                    <List.Item.Meta
                        avatar={<Avatar src={item.businessPhoto} />}
                        title={item.businessName}
                        description={`Last order : ${moment(item.lastActive).format("DD MMM YYYY hh:mm a")}`}
                    />
                    {/* <div>status</div> */}
                </List.Item>
            )}
        />
    )
}
export default App;