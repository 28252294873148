import React from 'react';
import { Card, Row, Col } from 'antd';

interface ParentCompProps {
    icon: React.ReactNode;
    title: string;
    primary: any;
    loading: boolean;
    secondary?: any;
}
interface Props extends ParentCompProps {
    title: string;
    loading: boolean;
    primary: any;
    secondary?: any;
}
const PanelCard: React.FC<ParentCompProps> = (props: Props) => {
    const { icon, title, primary, secondary, loading } = props
    return (
        <Card
            loading={loading}
        >
            <Row gutter={24}>
                <Col span={8} >
                    {icon}
                </Col>
                <Col span={12}>
                    <Row>
                        <h1>{title}</h1>
                    </Row>
                    <Row>
                        <h1>{primary}</h1>
                    </Row>
                    {secondary && (<Row>
                        <h1>{secondary}</h1>
                    </Row>)}
                </Col>
            </Row>
        </Card>

    )
}

export default PanelCard;