import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, List, Input } from 'antd';
import firebase from "firebase/app";
import { CopyOutlined, CodeOutlined } from '@ant-design/icons';
import { SmsMessageCode } from "../store";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const originData: SmsMessageCode[] = []
const MessageCodeList = () => {
    const [visible, setVisible] = React.useState(false);
    const [state, setState] = useState({ status: 'loading', data: originData })
    const [copyIndex, setCopyIndex] = useState(-1)
    const copied = (i: number) => i === copyIndex;
    useEffect(() => {
        const getCodes = firebase.functions().httpsCallable('getSmsCodes');
        getCodes({ merchantId: 'piticafe' }).then((result) => {
            const { status, data } = result.data as { status: 'success' | 'invalid', data?: any }
            if (status === 'success') {
                const fees = data as SmsMessageCode[]
                const _state = { ...state }
                setState({ ..._state, status: 'loaded', data: fees })
            }
        }).catch(error => {
            console.log(error)
        })
    }, [])
    const showModal = () => {
        setVisible(true);
    };
    const handleOk = async () => {
        setVisible(false)

    };
    const handleCancel = () => {
        setVisible(false);
    };
    return (
        <>
            <Button icon={<CodeOutlined />} onClick={showModal}>
                show message codes
            </Button>
            <Modal
                title="Message Codes"
                visible={visible}
                onOk={handleOk}
                confirmLoading={false}
                onCancel={handleCancel}
            >
                <Card loading={state.status !== 'loaded'}
                >
                    <List
                        itemLayout="horizontal"
                        dataSource={state.data}
                        bordered={false}
                        split={false}
                        renderItem={(item: SmsMessageCode, index: number) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Input value={item.name} disabled={true} />}
                                    title={<Input value={item.code} disabled={true}
                                        addonAfter={
                                            <CopyToClipboard
                                                text={item.code}
                                                onCopy={() => setCopyIndex(index)}
                                            >
                                                <span>
                                                    {
                                                        copied(index) ? <a style={{ color: 'green' }}>Copied!</a> : <CopyOutlined />
                                                    }
                                                </span>
                                            </CopyToClipboard>

                                        } />}
                                />
                                {item.description}
                            </List.Item>
                        )} />
                </Card>
            </Modal>
        </>
    );
};
export default MessageCodeList;
