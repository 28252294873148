import { Layout } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Dispatch } from 'redux';
import { userIsAuthenticated } from './authWrapper';
import { SideNav, TopBar } from './layout';
import webToolkit from './tools/webPortal';
import mToolkit from './tools/mcommerce';
import hostToolkit from './host/hostList';
import smsToolkit from './sms/smsList';
import shopList from './shops/shopList';
import shopDetail from './shops/shopDetail';
//import monitorEvent from './monitor/monitor';
import accountClone from './royalty/account_clone';
import trelloList from './trello/trelloList';
import { Dashboard as biAnyalticDashboard } from './bi_dashboards/index'
import { AppState } from './store';
import { summaryDashboard } from "./dashboards";
import ItemList from './shops/items/items';
/*global __COMMIT_HASH__*/
/*eslint no-undef: "error"*/

const { Header, Content, Footer } = Layout;


const ProtectedWebToolkit = userIsAuthenticated(webToolkit);
const ProtectedMCommerceToolkit = userIsAuthenticated(mToolkit);
const ProtectedSMSToolkit = userIsAuthenticated(smsToolkit);
const ProtectedHostToolkit = userIsAuthenticated(hostToolkit);
const ProtectedDashboard = userIsAuthenticated(summaryDashboard);
const ProtectedShopList = userIsAuthenticated(shopList);
const ProtectedTrelloList = userIsAuthenticated(trelloList);
const ProtectedShopDetail = userIsAuthenticated(shopDetail);
const ProctectedAccountClone = userIsAuthenticated(accountClone);
const ProtectedMonitorEvent = userIsAuthenticated(biAnyalticDashboard);
const ProtectedItemList = userIsAuthenticated(ItemList);
const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch, { history }: Props) => {
  return {
    onMenuClick: (item: { key: string }) => {
      history.push(`/${item.key}`);
    },
  };
};

interface Props extends RouteComponentProps<{}> {
  onMenuClick: (item: { key: string }) => void;
}

class Root extends React.Component<Props> {
  render() {
    const { onMenuClick, location } = this.props;
    const currentMenuItem =
      (location && location.pathname.split('/')[1]) || 'dashboard';

    return (
      <div className="App">
        <Layout style={{ minHeight: '100vh' }}>
          <SideNav
            onMenuClick={onMenuClick}
            currentMenuItem={currentMenuItem}
          />
          <Layout>
            <Header>
              <TopBar />
            </Header>
            <Content>
              <Switch>
                <Route exact={true} path="/" component={ProtectedDashboard} />
                <Route
                  path="/dashboard"
                  component={ProtectedDashboard}
                />
                <Route
                  path="/monitor"
                  component={ProtectedMonitorEvent}
                />
                <Route
                  path="/webPortal"
                  component={ProtectedWebToolkit}
                />
                <Route
                  path="/mCommerce"
                  component={ProtectedMCommerceToolkit}
                />
                <Route
                  path="/host"
                  component={ProtectedHostToolkit}
                />
                <Route
                  path="/trello"
                  component={ProtectedTrelloList}
                />
                <Route
                  path="/sms"
                  component={ProtectedSMSToolkit}
                />
                <Route
                  path="/shop"
                  component={ProtectedShopList}
                />
                <Route
                  path="/shopDetail/:id"
                  component={ProtectedShopDetail}
                />
                <Route
                  path="/items/:merchantId"
                  component={ProtectedItemList}
                />
                <Route
                  path="/accountClone"
                  component={ProctectedAccountClone}
                />
              </Switch>
            </Content>
            <Footer>
              <span>
                &copy; {new Date().getFullYear()}{' '}
                <a href="https://datafocus.cloud/">Data Focus Pte Ltd</a>
              </span>
              <span id="commit-hash">{__COMMIT_HASH__}</span>
            </Footer>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Root);
