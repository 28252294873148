import React, { useState, useEffect } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Card } from 'antd';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useAlert } from 'react-alert';
import { getOrders } from "../api";
import firebase from 'firebase/app';
import Item from 'antd/lib/list/Item';
import * as _ from 'lodash';
import moment from 'moment';

interface Item {
    key: string;
    merchantId: string;
    docId: string;
    orderId: string;
    customer: string;
    totalAmount: string | number;
    date: string,
    point: string,
    status: string,
    taxAmount: string | number,
    type: string,
    payment: string
}
interface Props {
    merchantId: string;
}
const originData: Item[] = [];
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: Item;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const disable = dataIndex === 'hostId'
    const inputNode = inputType === 'number' ? <InputNumber disabled={disable} /> : <Input disabled={disable} />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const DeleteableOrderList = (props: Props) => {
    const { merchantId } = props
    const alert = useAlert();
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [deleteKey, setDeleteKey] = useState('')
    const [searchText, setSearchText] = useState('')
    const [state, setState] = useState({ status: 'loading', data: originData, saveLoading: false })
    const isEditing = (record: Item) => record.key === editingKey;
    const isDeleting = (record: Item) => record.key === deleteKey;
    useEffect(() => {
        getOrders(merchantId).then((result) => {
            if (result.success && result.orders) {
                const _orders = _.orderBy(result.orders, 'createdOn', 'desc')
                const data = _orders.map((row, index) => {
                    return {
                        key: index.toString(),
                        merchantId,
                        docId: row.id,
                        orderId: row.orderId,
                        customer: row.customerName,
                        totalAmount: row.totalAmount,
                        date: moment(row.createdOn).format("DD MMM YYYY hh:mm a").toString(),
                        point: row.earnPoints,
                        status: row.orderStatus,
                        taxAmount: row.totalAddedTaxAmount,
                        type: row.orderType,
                        payment: row.paymentType
                    } as Item
                })
                const _state = state
                setState({ ..._state, status: 'loaded', data })
            }
        });
    }, [merchantId])
    const cancel = () => {
        setEditingKey('');
    };
    const onDelete = async (v: Item) => {
        setDeleteKey(v.key)
        const hostfunc = firebase.functions().httpsCallable('orderByActionType');
        const result = await hostfunc({
            actionType: 'delete',
            id: v.docId,
            merchantId: v.merchantId,
        });
        const { success, code } = result.data as { success: boolean, data?: any, code?: string }
        if (success) {
            alert.success(`success: delete ${v.docId}`)
            setState({ ...state, data: state.data.filter(b => b.key !== v.key) });
        } else {
            alert.error(`failed : delete ${v.docId} ${code}`)
        }
        setDeleteKey('');
    }
    const onSearch = (search: any) => {
        if (state.status === 'loaded') {
            setSearchText(search)
        }
    }
    const columns = [
        {
            title: 'OrderId',
            dataIndex: 'orderId',
            key: 'orderId',
            // align: 'left',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value: string, record: any) => record && record.orderId && record.orderId.includes(value),
            ellipsis: true,
            editable: true,
            render: (text: any) => <b style={{ color: 'blue' }}>{text}</b>
        },
        {
            title: 'docId',
            dataIndex: 'docId',
            key: 'docId',
            // align: 'left',
            editable: true,
            render: (text: any) => <b style={{ color: 'blue' }}>{text}</b>
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            editable: true,
            render: (text: any) => text
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            editable: true,
            render: (text: any) => text
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            editable: true,
            render: (text: any) => text
        },
        {
            title: 'Points',
            dataIndex: 'point',
            key: 'point',
            editable: true,
            render: (text: any) => text
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            editable: true,
            render: (text: any) => <b style={{ color: text === 'Completed' ? 'green' : text === 'Pending' ? 'peru' : 'red', fontWeight: 'bold' }}>{text}</b>
        },
        {
            title: 'Order Type',
            dataIndex: 'type',
            key: 'type',
            editable: true,
            render: (text: any) => text
        },
        {
            title: 'Payment',
            dataIndex: 'payment',
            key: 'payment',
            editable: true,
            render: (text: any) => text
        },
        {
            title: 'action',
            //width: '25%',
            dataIndex: 'action',
            render: (_: any, record: Item) => {
                const deleting = isDeleting(record)
                return (
                    deleting ? <Button type='primary' loading>deleting...</Button> :
                        <Popconfirm title={`Sure to delete ${record.docId}?`} onConfirm={() => onDelete(record)}>
                            <Button icon={<DeleteOutlined />} danger >delete</Button>
                        </Popconfirm>
                );
            }
        },
    ];

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Item) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    return (
        <Card
            title="Orders"
            loading={state.status === 'loading'}
            style={{ border: 'none' }}
            extra={
                <>
                    <Input
                        placeholder="search orderId..."
                        size="default"
                        onChange={(e: any) => onSearch(e.target.value)}
                    />
                </>
            }

        >
            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered={false}
                    dataSource={state.data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: cancel,
                    }}
                />
            </Form>
        </Card>

    );
};

export default DeleteableOrderList;